import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { LocationIndex, PlaceResult } from "./BookingEntities";
import { CheckCabServiceAsync } from "./CheckCabService";
import { ConsiderFareUpdate } from "../Condition/FareLoader";
import { ConsiderLongDistance } from "../Utils/LongDistanceHelper";
import { ConsiderConditionUpdate } from "../Condition/ConditionLoader";
import { ConsiderConditionUpdateV2 } from "../Condition/ConditionLoaderV2";
import { AsyncUpdateOutcome } from "../../modules/Condition/AsyncUpdateOutcome";
import { GeoPoint, KnownLocation, LocationSourceKind } from "../Location/Entities";
import { GooglePlaceToAddressV2Details, GooglePlaceToLocation } from "../../widgets/google-maps/ParseGooglePlace";
import { ServiceKind } from "../../utils/service-vehicles/ServiceMetadata";
import { LogEvent } from "../../utils/LogEvent";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { CheckCabServiceAvailabilityV2 } from "./CheckCabServiceAvailabilityV2";
import { VehicleOption } from "../Condition/Redux/ConditionEntities";
import { GooglePlaceSessionManager } from "../AddressPicker/GoogleApis/GooglePlaceSessionManager";
import { AddressV2 } from "../../Services/MakeBookingContracts";
import { ApiGeoPoint } from "../../Services/BookingEntities";

interface AutocompleteServiceResponse {

    /** [result] is non-null when [status] is "OK". */
    result: google.maps.places.AutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
}

interface PlacesServiceResponse {
    result: google.maps.places.PlaceResult,
    status: google.maps.places.PlacesServiceStatus
}

export function ConvertToPlaceResult(place: google.maps.places.PlaceResult, exactDisplayText: string | null) {
    
    const googleGeo = place.geometry!.location!;
    
    const location : GeoPoint = { latitude: googleGeo.lat(), longitude: googleGeo.lng() };
    
    const placeResult : PlaceResult = {
        place,
        location,
        ExactDisplayText: exactDisplayText
    };

    return placeResult;
}

export async function ConvertGeoCoderResultToPlaceResult(result: google.maps.GeocoderResult, location: GeoPoint) {

    const placeId = result.place_id;
    const place = await LoadGooglePlaceDetails(placeId);

    if (!place) return null;

    const placeResult : PlaceResult = {
        place,
        location,
        ExactDisplayText: result.formatted_address,
    };

    return placeResult;
}

/**
 * Uses AutocompleteService, to retrieve the place_id using the address text 
 */
export async function GetPlaceIdByAddress(inputAddress : string) : Promise<string | null> {

    if(!inputAddress) return null;

    const autoCompleteService = new google.maps.places.AutocompleteService;

    const promise = new Promise<AutocompleteServiceResponse>((resolve) => {
        autoCompleteService.getPlacePredictions({input: inputAddress}, (result, status) => resolve({ result, status }));
    });

    const { result, status } = await promise;

    if (status === google.maps.places.PlacesServiceStatus.OK && result && result[0]) {
        const placeId = result[0].place_id;
        return placeId; 
    }
    else {
        appInsights.trackEvent("GetPlaceIdByAddress => AutocompleteService Failure", { 
            errorNumber: status.toString(),
            errorMessage: google.maps.places.PlacesServiceStatus[status],
            placeText: inputAddress
        });
    }

    return null;
}

/**
 * Given a Google Maps Place ID, loads the details of that place from Google.
 */
export async function LoadGooglePlaceDetails(placeId: string): Promise<google.maps.places.PlaceResult | null> {
    
    const placesService = new google.maps.places.PlacesService(document.createElement('div'));

    const request: google.maps.places.PlaceDetailsRequest = {
        placeId: placeId,
        fields: ['address_components', 'geometry', 'name', 'place_id', 'formatted_address'], // don't need "Contact Data" or "Atmosphere Data" SKUs, so save some money
    }

    const sessionToken = GooglePlaceSessionManager.TryGetExistingOnly();

    if (sessionToken) {
        request.sessionToken = sessionToken;
    }

    const promise = new Promise<PlacesServiceResponse>((resolve) => {
        placesService.getDetails(request, (result, status) => resolve({ result, status }));
    });

    GooglePlaceSessionManager.CloseSession();

    const { result, status } = await promise;
 
    if (status === google.maps.places.PlacesServiceStatus.OK && result) {
        return result;
    }
    else {
        appInsights.trackEvent("GetPlaceResultByPlaceId => PlacesService Failure", { 
            errorNumber: status.toString(),
            errorMessage: google.maps.places.PlacesServiceStatus[status],
            placeText: placeId
        });
    }
        
    return null;
}

/**
 * Populate pickup address
 * Set user's location preference
 * Validate the pickup address and longer distance
 * Invoke check cab availability and load conditions per version required
 */
export async function ApplyPickupLocationSelection(result: PlaceResult): Promise<AsyncUpdateOutcome>{

    Dispatch.GoogleMap.PickupSelected(result.location);

    const addressV2 = ConvertPlaceResultToAddressV2(result);
    Dispatch.Booking.Address(LocationIndex.Pickup, addressV2);
    
    // location context
    ConsiderLocationContextNotification(result.place);

    // This is only to show pop-up if both A & B are available
    ConsiderLongDistance();
    
    /**
     * Check cab's serviceability;
     * Consider to load vehicle conditions;
     * 
     * There are to versions to do so:
     *     V1 (ApplyPickupSelectionHandler) is from Booking API;
     *     V2 (CheckCabServiceAvailabilityService) is from Global Booker.
     */
    let status;

    if (FeatureFlags.BookingApiV2) {
        status = await ApplyPickupSelectionHandlerV2(result.place);
    }
    else {
        status = await ApplyPickupSelectionHandlerV1(result.place);
    }

    return status;
}

export function ConvertPlaceResultToAddressV2(result: PlaceResult): AddressV2 {

    const geoLocation: ApiGeoPoint = {
        Latitude: result.location.latitude,
        Longitude: result.location.longitude,
    };

    const addressV2Details = GooglePlaceToAddressV2Details(result.place);

    const addressV2: AddressV2 = {
        ...addressV2Details,
        FullTextAddress: result.ExactDisplayText ?? result.place.formatted_address ?? null,
        GeoLocation: geoLocation,
        GoogleMapsPlaceId: result.place.place_id!,
    }

    return addressV2;
}

/**
 * V1 is from Booking API:
 *   1) Check cab serviceability
 *   2) Consider load vehicle conditions
 */
async function ApplyPickupSelectionHandlerV1(place: google.maps.places.PlaceResult) : Promise<AsyncUpdateOutcome>{

    // servicability check:
    await CheckCabServiceAsync(place);

    // conditions
    const status = await ConsiderConditionUpdate();
    PostCabServiceAvailabilityCheck(status);

    return status;
}

/**
 * V2 is from Global Booker:
 *   1) Check cab serviceability
 *   2) Consider calculate vehicle conditions (already obtained API response)
 */
async function ApplyPickupSelectionHandlerV2(place: google.maps.places.PlaceResult): Promise<AsyncUpdateOutcome>{

    // servicability check:
    const serviceAvailability = await CheckCabServiceAvailabilityV2(place);

    // conditions
    const status = await ConsiderConditionUpdateV2(serviceAvailability);
    PostCabServiceAvailabilityCheck(status);

    return status;
}

/**
  * Once the pickup address is selected and conditionlist is loaded,
  * show error message if the selected vehicle is not present in the conditionlist
  */
function PostCabServiceAvailabilityCheck(status : AsyncUpdateOutcome) {

    if (status === AsyncUpdateOutcome.Success || status === AsyncUpdateOutcome.InputsUnchanged) {
                       
        const { condition } = appstore.getState();
        
        const selectedVehicle = condition.SelectedCondition.Service.displayName;
        
        let isVehicleServiceable = condition.ConditionList.some(x => x.Service.displayName === selectedVehicle);
                
        Dispatch.UILogicControl.ValidateVehicleOnPickupChange(isVehicleServiceable);

        if (!FeatureFlags.BookingApiV2) {
            ValidateMaxSeatForMaxiTaxi();
        }
    }
}

/**
 * Populate dropoff address
 * Calculate the Fare
 * Validate the longer distance
 */
export async function ApplyDropoffLocationSelection(result : PlaceResult) {

    Dispatch.GoogleMap.DropoffSelected(result.location);

    const addressV2 = ConvertPlaceResultToAddressV2(result);
    Dispatch.Booking.Address(LocationIndex.Dropoff, addressV2);
    
    LogEvent.DropoffAddressSelected();

    // fare
    ConsiderFareUpdate();

    // This is only to show pop-up if both A & B are available
    ConsiderLongDistance();
}

/** Based on the Place Result for the pickup address, we may update the user's location preference. The only issue is that GooglePlaceToLocation may fail.*/
function ConsiderLocationContextNotification(place: google.maps.places.PlaceResult) {

    const locationData = GooglePlaceToLocation(place, true);

    if (locationData === null) {

        // TODO: handle better
        return;
    }

    const userLocation: KnownLocation = {
        isKnown: true,
        source: LocationSourceKind.UserSelected,
        value: locationData,
    };

    // push to app state
    Dispatch.Location.userSelectsLocation(userLocation);
}

/**
 * Validate Maxi-Taxi,
 * 1. "SelectedCondition" max seat value must not be more, than the selected suburb's available max seat value
 * 2. Assign the selected suburb's available max seat value to "SelectedCondition" max seat value
 */
function ValidateMaxSeatForMaxiTaxi() {

    const conditionData = appstore.getState().condition;
    const selectedOption = conditionData.SelectedCondition;

    // Validate for maxi taxi only
    if (selectedOption.Service.kind !== ServiceKind.MaxiTaxi) return;

    // Validate the selected condition
    if (!selectedOption.ApiVehicle) return;

    // Retrieve maxi taxi details from the condition list
    const selectedVehicle = conditionData.ConditionList.find(x => x.Service.displayName === selectedOption.Service.displayName);

    // Validate max taxi details
    if (!selectedVehicle) return;    

    const maxSeat = conditionData.MaxiTaxiSeaterInfo.Maximum;
    
    // Validate max seat value
    if (selectedOption.ApiVehicle.MaxSeat <= maxSeat) return;

    // Assign the max available seat
    const maxiTaxi = conditionData.MaxiTaxiLookUp[maxSeat];

    const maxi: VehicleOption = {
        ...selectedVehicle,
        ApiVehicle: maxiTaxi
    };

    // Update the "SelectedCondition" in the store
    Dispatch.Condition.SelectVehicle(maxi);
}