import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ApplicationState } from '../../appState';
import { RouteUrls } from '../../RouteUrls';
import { GoogleMapWrapped } from '../../widgets/google-maps/GoogleMapWrapped';
import SiteHeader from '../../widgets/Header/SiteHeader';
import BookingDateTime from '../Booking/BookingDateTime';
import GoogleMapWrapperWithGps from '../Booking/Mobile/GoogleMapWrapperWithGps';
import BookingAddressGroup from '../Booking/Widget/BookingAddressGroup';
import { VehicleSelector } from '../Condition/UI/VehicleSelector';
import '../Booking/Mobile/MobileBookingPage.scss';
import '../Booking/Mobile/MobileBookingForm.scss';
import TrackVisibility from 'react-on-screen';
import { TrackVisibilityBlock } from '../Booking/Mobile/TrackVisibilityBlock';
import { Dispatch } from '../Dispatch';
import { InitVehicleState } from '../Condition/Redux/ConditionState';
import { BookingFormGroup } from '../Booking/FormSteps/BookingFormGroup';
import { MobileMenu } from '../User/MobileMenu';

interface MobileFareEstimatorCoreProps {
    ShouldShowHeader: boolean;
}

/**
* This is a wrapper of fare estimator mobile view layout.
*/
const MobileFareEstimatorCore: React.FC<MobileFareEstimatorCoreProps> = (props) => {
    const history = useHistory();

    useEffect(() => {

        Dispatch.Condition.SelectVehicle(InitVehicleState);

        // Currently opened.
        Dispatch.Condition.CollapseVehicleSelectorUI();

    }, []);

	function GoToBookingWidget() {
		history.push(RouteUrls.BookingWidget);
	}

	return <>
		<div className={props.ShouldShowHeader ? "mobile-booking-page-top-section top-section-display" : "mobile-booking-page-top-section top-section-hide"}>
			<SiteHeader />
            <MobileMenu />
		</div>

		<div className="mobile-booking-form-panel" style={{ top: 64 }}>

			<TrackVisibility partialVisibility>
				<TrackVisibilityBlock />
			</TrackVisibility>

			<GoogleMapWrapperWithGps ShowGps={true}>
				<GoogleMapWrapped />
			</GoogleMapWrapperWithGps>

			<div className="fare-estimator-title" style={{ marginTop: 25 }}>
				<h2>Fare Estimate Calculator</h2>
				<p>Please enter a valid pickup and destination.</p>
			</div>

			<BookingFormGroup>
				<BookingAddressGroup />
				<BookingDateTime />
				<VehicleSelector />
			</BookingFormGroup>

			<div className="booking-btn-panel">
				<Button style={{fontSize: "17px"}} variant="contained" size="medium" color="primary" onClick={GoToBookingWidget}>
					Next
				</Button>
			</div>

		</div>
	</>;
};

function mapStateToProps(state: ApplicationState): MobileFareEstimatorCoreProps {
    return {
        ShouldShowHeader: state.uiLogicControl.BookingForm.ShouldShowHeader,
    }
}

export const MobileFareEstimatorPage = connect(mapStateToProps)(MobileFareEstimatorCore);
