import { BookingTrackingResult, NotesToDriverResult, BookingAuthority, LightweightBookingStatus, MyBookingsRequest, ShareBookingRequest, ReadOnlyHash, BookingLocationResult, BookingDriverDetails, Condition, BookingRequestV1b } from './BookingEntities';
import { ServiceResult } from './ServiceEntities';
import { Get, GetManagementServiceRootUrl, Post } from './ServiceManager';
import { MiscBookingDetails } from '../modules/Booking/MiscBookingEntities';

/** Base URL for the BookingController in the Booking Management Service 
*  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}Booking/`;
}

/** Client for the BookingController in the Management API */
class BookingServiceClass {

    /** Gets the vehicle conditions of the fleet that services the specified GPS coordinates.
     * If you have a Suburb ID (from CheckCabService), call GetCarsBySuburbId instead, since it will be quicker. */
    public GetCarsByGeoPointV2(latitude: number, longitude: number): Promise<ServiceResult<Condition[]>> {

        const url = `${GetBaseUrl()}GetCarsByGeoPointV2?latitude=${latitude}&longitude=${longitude}`;
        return Get<Condition[]>(url);
    }

    /** Gets all vehicle conditions available in the specified Suburb. Suburb ID is the internal ID of the Booking API. This is returned by CheckCabService. */
    public GetCarsBySuburbId(suburbId: number): Promise<ServiceResult<Condition[]>> {

        const url = `${GetBaseUrl()}GetCarsBySuburbID?suburbID=${suburbId}`;
        return Get<Condition[]>(url);
    }

    /** Gets all conditions available in the specified Suburb. Suburb ID is the internal ID of the Booking API. This is returned by CheckCabService. */
    public GetAllConditionsBySuburbId(suburbId: number): Promise<ServiceResult<Condition[]>> {

        const url = `${GetBaseUrl()}GetConditionsBySuburbID?suburbID=${suburbId}`;
        return Get<Condition[]>(url);
    }

    /** Gets a detailed copy of the specified booking, including vehicle location and driver details.
     * In this overload, the caller passes the ID and Hash, proving ownership of the booking.
     * This is preferred over passing just the hash, because it avoids timing issues after CreateBooking. */
    public TrackBookingByKey(bookingKey: BookingAuthority): Promise<ServiceResult<BookingTrackingResult>> {
        const url = `${GetBaseUrl()}TrackBookingByKey`;
        return Post<BookingTrackingResult>(url, bookingKey);
    }

    /** 
     * Gets the booking location input
     */
    public GetBookingLocationDetialsById(bookingId: number): Promise<ServiceResult<BookingLocationResult[]>> {
        const url = `${GetBaseUrl()}GetBookingLocationDetails?bookingId=${bookingId}`;
        return Get<BookingLocationResult[]>(url);
    }

    /** Update the Driver notes for a specific Booking. 
     * INFO: Fallback is required(By the caller)  as there could be chance of underlying system doesn't have the Booking details   */
    public SendNotesToDriver(bookingID: string, notes: string): Promise<ServiceResult<NotesToDriverResult>> {
        const request = {
            BookingId: bookingID,
            Notes: notes
        };

        const url = `${GetBaseUrl()}SendDriverNotes`;
        return Post<NotesToDriverResult>(url, request);
    }

    /** This one is fairly self explanatory! */
    public CreateBookingV1b(request: BookingRequestV1b): Promise<ServiceResult<BookingAuthority>> {
        const url = `${GetBaseUrl()}CreateV1b`;
        return Post<BookingAuthority>(url, request);
    }

    /** Cancel a booking, using its ID + Access Code. */
    public CancelByKey(bookingKey: BookingAuthority): Promise<ServiceResult<void>> {

        const url = `${GetBaseUrl()}CancelByKey`;
        return Post<void>(url, bookingKey);
    }

    /** Lightweight booking status refresh for multiple bookings. */
    public GetBookingStatusBulk(bookingKeys: BookingAuthority[]): Promise<ServiceResult<LightweightBookingStatus[]>> {
        const url = `${GetBaseUrl()}BookingStatusBulk`
        return Post<LightweightBookingStatus[]>(url, bookingKeys);
    }

    /** Get the list of recent bookings of logged in user. */
    public GetMyBookings(requestData: MyBookingsRequest): Promise<ServiceResult<BookingTrackingResult[]>> {
        const url = `${GetBaseUrl()}GetMyBookings`;
        return Post<BookingTrackingResult[]>(url, requestData);
    }

    /** Transfer bookings created while a guest to the user's signed in account */
    public TakeOwnershipOfGuestBookings(bookingKeys: BookingAuthority[]): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}TakeOwnership`
        return Post<void>(url, bookingKeys);
    }

    /** Send an SMS to the given mobile number with the URL contains read only hashcode. */
    public ShareBooking(requestData: ShareBookingRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}SendBookingSms`;
        return Post<void>(url, requestData);
    }

    /** Get the share booking URL for the selected booking with the read only hashcode */
    public GetShareURL(bookingData: BookingAuthority): Promise<ServiceResult<ReadOnlyHash>> {
        const url = `${GetBaseUrl()}GetHashCodeReadOnly`;
        return Post<ReadOnlyHash>(url, bookingData);
    }

    /** Get the miscellaneous booking details of  the given booking. */
    public GetMiscBookingDetails(booking: BookingAuthority): Promise<ServiceResult<MiscBookingDetails>> {
        const url = `${GetBaseUrl()}GetMiscBookingDetails`;
        return Post<MiscBookingDetails>(url, booking);
    }

    /** Get the basic driver details of the assigned driver for the specified booking. */
    public GetDriverDetails(booking: BookingAuthority): Promise<ServiceResult<BookingDriverDetails>> {
        const url = `${GetBaseUrl()}DriverDetails`;
        return Post<BookingDriverDetails>(url, booking);
    }

    /**
     * Check the validity (only) of a booking link.
     */
    public CheckLink(link: BookingAuthority): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}CheckLink`;
        return Post<void>(url, link);
    }

    /**
     * Load one or more bookings by their links.
     */
    public GetBookingsByLinkBulk(links: BookingAuthority[]): Promise<ServiceResult<BookingTrackingResult[]>> {
        const url = `${GetBaseUrl()}GetByLinkBulk`;
        return Post<BookingTrackingResult[]>(url, links);
    }

    /** Loads proof of delivery image urls for the specified booking. */
    public GetProofOfDeliveryImageUrls(booking: BookingAuthority): Promise<ServiceResult<string[]>> {
        const url = `${GetBaseUrl()}GetProofOfDeliveryImageUrls`;
        return Post<string[]>(url, booking);
    }

    /** A temporary trip ID for the ODRD project. */
    public GetTempTripId(): Promise<ServiceResult<string>> {
        const url = `${GetBaseUrl()}GetTempTripId`;
        return Post<string>(url, null);
    }
}

/** Singleton export for static access to the operations */
export const BookingService = new BookingServiceClass();