import React, { FC } from 'react';
import { RecentTripButtonKind } from '../../UILogicControl/UILogicControlEntities';
import { PopulateSelectedTripDetails } from './PopulateSelectedTripDetails';
import { QuickBookSource } from './QuickBookEntities';
import { BookingInfo } from '../../MyBookings/MyBookingEntities';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';

interface RecentTripsMenuProps {
    Booking: BookingInfo;
    IsDisabled: boolean
}

export const RecentTripEntry: FC<RecentTripsMenuProps>= (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    const onClickHandler = async (btnClicked: RecentTripButtonKind, trackingResult : BookingInfo) => {
        
        // Do nothing, if the booking buttons are disabled        
        if(props.IsDisabled) return;

        PopulateSelectedTripDetails(btnClicked, trackingResult, QuickBookSource.RecentTrips);
    }
    
    return (
        <div className="booking-template">
            <p className="passenger-name">{props.Booking.PassengerName}</p>
                {props.Booking.Time !== "" ? 
                <p className="passenger-time">{moment(props.Booking.Time, "MM/DD/YYYY HH:mm:SS").format("DD MMM YYYY hh:mma")} 
                    <Tooltip title="Book return and more" arrow>
                        <IconButton aria-controls="open-menu" onClick={handleMenuOpen} size="large">
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip> 
                </p> : ""}
                    <Menu
                        id="open-menu"
                        keepMounted
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => onClickHandler(RecentTripButtonKind.BookAgain, props.Booking)}>Book Again</MenuItem>
                        <MenuItem onClick={() => onClickHandler(RecentTripButtonKind.BookReturn, props.Booking)}>Book Return</MenuItem>
                    </Menu>
                <p>
                    <span className="dot default-brand-color"></span>
                    <span className="booking-txt">{props.Booking.PickupText}</span>
                    </p>
                <p className="default-gray-background">
                    <span className="dot"></span>
                    <span className="booking-txt">{props.Booking.DropoffText}</span>
                </p>
        </div>
    );
}

