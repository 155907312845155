import React from "react";
import "./TripHistory.scss";
import { getContentUrl, ContentURL } from "../Utils/ContentURL";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { SimpleUserProfile } from '../../modules/User/ProfileEntitiesV2';
import { Config } from "../../Config/Config";
import { GetTripHistory } from "../Booking/BookingLoaders";
import { BookingInfo } from "../MyBookings/MyBookingEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { GetTripHistoryV2 } from "../BookingV2/BookingLoadersV2";
import { DataGrid, GridCsvGetRowsToExportParams, GridRowId, gridSortedRowIdsSelector, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Tooltip } from "@mui/material";
import { TaxiHistoryGridColumns } from "./TripHistoryGridDefinition";

// styling data grid component using sx props.
const datagridSx = {
    padding: 10,
    "& .MuiDataGrid-virtualScrollerRenderZone": {
        "& .MuiDataGrid-row": {
            "&:nth-child(odd)": { backgroundColor: "#f8f6f2" }
        }
    },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#a4a4a46b",
        color: "black",
        "& .MuiDataGrid-columnHeaderTitle":{
            fontWeight: "bold"
        }
    },
    "& .MuiTablePagination-root .MuiToolbar-root": {
        marginLeft: 0
    }
}

interface TripHistoryState {
    listOfBookings: BookingInfo[];
    showLoader: boolean;
}

interface PropsFromStore {
    UserProfile: SimpleUserProfile | undefined;
}

/** Component which contains a table of Trip history of the logged in user. */
class TripHistory extends React.Component<PropsFromStore, TripHistoryState> {
    
    private inactiveBookingStatusList = Config.PastBookingTripStatusList;

    constructor(props: PropsFromStore) {
        super(props);

        this.state = {
            listOfBookings: [],
            showLoader: false
        }
    }

    async componentDidMount() {
        this.setState({showLoader: true});
        let tripHistory;

        if (!FeatureFlags.BookingApiV2) {
            tripHistory = await GetTripHistory(Config.StatusListForRecentTripList);
        } else {
            tripHistory = await GetTripHistoryV2(Config.StatusListInHistoryFromV2API);
        }

        this.setState({ showLoader: false });

        // Sort the booking list by booking status, display the booking with status "Booked" to appear at the top of the list.
        if (tripHistory) {
            tripHistory.sort(a => this.inactiveBookingStatusList.includes(a.TrackingInfo.BookingStatusID.toString()) ? 1 : -1);
            this.setState({listOfBookings: tripHistory});
        }
    }

    /**
     * A function which determines which row in the grid will be exported in CSV export.
     * This uses an in-buit selector to export the rows in their current sort order.
     */
    GetExportedRowIds(params: GridCsvGetRowsToExportParams): GridRowId[] {
        return gridSortedRowIdsSelector(params.apiRef);
    }

    render() {

        // Rendering Row on MUI Data Grid.
        const rowData = this.state.listOfBookings;
        const columns = TaxiHistoryGridColumns;

        const noBookingsMessage = (<div className="history-message">There are no bookings in your history.</div>);
        const loadingErrorMessage = (<div className="history-message">Oops, there was an error loading the data. Try refreshing the page.</div>);
            
        return (
            <div className="trip-history-main-panel">
                {this.state.listOfBookings ? this.state.listOfBookings.length === 0 && this.state.showLoader === false ? noBookingsMessage :  
                    <DataGrid 
                        autoHeight
                        rows={rowData} 
                        columns={columns}
                        sx={datagridSx}
                        getRowId={(booking) => booking.BookingID }
                        components={{Toolbar: () => {
                            return <GridToolbarContainer>
                                        <Tooltip arrow title="Export trip history into a CSV" placement="top-end" 
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    color: "white",
                                                    backgroundColor: "black"
                                                }
                                            }
                                        }}>
                                    <GridToolbarExport variant="contained" color="primary" size="medium" style={{ marginTop: "15px", marginBottom: "15px", fontWeight: "bold", letterSpacing: "1px" }} printOptions={{ disableToolbarButton: true }} csvOptions={{ getRowsToExport: this.GetExportedRowIds }} />
                                        </Tooltip>
                                </GridToolbarContainer>
                        }}}
                    />
                    : loadingErrorMessage}
                { this.state.showLoader ? <img alt="loading" src={getContentUrl(ContentURL.images.Loading)} className="loading-history" /> : null }
            </div>      
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        UserProfile: state.authentication.UserProfile
    };
}

export default connect(mapStateToProps)(TripHistory);