import { ParseAndStoreContactNumber, PopulateDropoffAddress, PopulatePickupAddress } from "../Booking/NewBookingTabHelper";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { PopulateConditionListWithDefaultVehicles } from "../Condition/PopulateConditions";
import { SelectVehicleByService } from "../Condition/SelectVehicleByService";
import { ReadUrlPrefillData } from "./ReadUrlPrefillData";
import { PrefillOptions, PrefillTip, StartupFeature } from "./PrefillOptions";
import { GetMyBrand } from "../../Config/MyAppConfig";
import { Brand } from "../../Config/Entities/Brand";
import { LocationIndex } from "../Booking/BookingEntities";

/** Handle all the booking widget data pre-fills from the query string in the URL. */
export function PreFillDataFromUrl() {

    var prefill = ReadUrlPrefillData();

    PopulateService(prefill);
    ApplyCustomFeatures(prefill);
    PopulateBookingDetails(prefill);
    ShowScenarioTipDialog(prefill);
}

/**
 * Populates the service i.e. maxi, parcel, etc. in the vehicle selection bar
 */
function PopulateService(prefill: PrefillOptions) {

    if (prefill.Service) {
        PopulateConditionListWithDefaultVehicles();
        SelectVehicleByService(prefill.Service);
    }
}

/**
 *  Completely custom extra behaviours.
 */
function ApplyCustomFeatures(prefill: PrefillOptions) {

    if (prefill.Feature == StartupFeature.FareEstimate) {
        Dispatch.UILogicControl.ShowFareEstimateMessage();
    }   
}

/** Populate details as much as possible from the query string data. */
function PopulateBookingDetails(prefill: PrefillOptions) {

    // Populate passenger name
    if (prefill.CustomerName) {
        Dispatch.Booking.ContactName(LocationIndex.Pickup, prefill.CustomerName);
    }

    // Phone number
    if (prefill.PhoneNumber) {
        ParseAndStoreContactNumber(prefill.PhoneNumber);
    }

    // for now, driver notes are stored in a single field; not per-location
    const driverNote = GetBestCombinedDriverNote(prefill);

    if (driverNote) {
        Dispatch.Booking.DriverNotes(LocationIndex.Pickup, driverNote);
    }

    ExecuteWhenGoogleLoaded(() => PopulateAddressesFromUrl(prefill));
}

/**
 * Combine all the parts that contribute to the driver note into a single string.
 * Returns null if there are no contributions.
 */
function GetBestCombinedDriverNote(prefill: PrefillOptions): string | null {

    const parts: string[] = [];

    if (prefill.PickupInstruction) {
        parts.push(prefill.PickupInstruction);
    }

    if ((prefill.ScenarioTip == PrefillTip.ClickAndCollect) || prefill.OrderNumber) {
        parts.push(`Please collect my order using the following reference number: ${prefill.OrderNumber ?? ""}`);
    }

    if (prefill.DropoffInstruction) {
        parts.push(prefill.DropoffInstruction);
    }

    if (parts.length === 0) return null;

    return parts.join(" --- ");
}

/** Populate pickup and dropoff addresses. */
function PopulateAddressesFromUrl(prefill: PrefillOptions) {

    // Pickup
    if (prefill.PickupAddress) {
        PopulatePickupAddress(null, prefill.PickupAddress);
    }

    // Dropoff
    if (prefill.DropoffAddress) {
        PopulateDropoffAddress(null, prefill.DropoffAddress);
    }
}

/** The above functions run on page load (if query string presents) and google map is also loading simultaneously. 
 * When address is being processed, the map might not be still loaded and returns an error 'google is not defined'. 
 * Therefore, run address processing code only after google is loaded.
 * This function can also be used in other places where waiting for the google to loaded is required. */
function ExecuteWhenGoogleLoaded(func: Function) {
    if (typeof google !== 'undefined') {
        func();
    }
    else {
        setTimeout(function () {
            (function (f) {
                ExecuteWhenGoogleLoaded(f)
            })(func)
        }, 500);
    }        
}

/** For special scenarios, show a custom dialog to explain the situation */
function ShowScenarioTipDialog(prefill: PrefillOptions) {

    if (prefill.ScenarioTip === PrefillTip.ClickAndCollect) {

        // this currently has 13cabs-specific UI
        if (GetMyBrand() === Brand.OneThreeCabs) {
            Dispatch.Dialog.ShowDialog(DialogKind.ClickAndCollectInfo);
        }
    }
    else if (prefill.ScenarioTip === PrefillTip.PassengerTrip) {
        Dispatch.Dialog.ShowDialog(DialogKind.PassengerInfo);

        if (!prefill.Service) {
            Dispatch.Condition.ShowVehicleSelectorUI();
        }
    }
}