import { GeoPoint } from "../../modules/Location/Entities";
import { toGoogleLatLng } from "./toGoogleLatLng";
import { DirectionsData } from "../../modules/GoogleMap/GoogleMapEntities";

interface DirectionsResponse {
    result: google.maps.DirectionsResult | null;
    status: google.maps.DirectionsStatus;
}

/** Given the "A" and "B" locations, computes and returns a google Directions result for the trip. */
export async function loadGoogleDirections(pickup: GeoPoint, dropoff: GeoPoint, forEta: boolean): Promise<DirectionsData | null>  {
    const service = new google.maps.DirectionsService();

    const request: google.maps.DirectionsRequest = {
        origin: toGoogleLatLng(pickup),
        destination: toGoogleLatLng(dropoff),
        travelMode: google.maps.TravelMode.DRIVING,
    };

    // include live traffic for higher fidelity estimates
    if (forEta) {
        request.drivingOptions = {
            departureTime: new Date(),
            trafficModel: google.maps.TrafficModel.BEST_GUESS,
        }
    }


    // this converts the directions API's callback into a promise
    var promise = new Promise<DirectionsResponse>((resolve) => {
        service.route(request, (result, status) => resolve({ result, status }));
    });

    const response = await promise;

    if (response.status !== google.maps.DirectionsStatus.OK) {

        const statusName = google.maps.DirectionsStatus[status];

        appInsights.trackEvent("Google Directions Failure", {
            errorNumber: status.toString(),
            errorMessage: statusName,
            pickupLatitude: pickup.latitude.toString(),
            pickupLongitude: pickup.longitude.toString(),
            dropoffLatitude: dropoff.latitude.toString(),
            dropoffLongitude: dropoff.longitude.toString(),
        });

        return null;
    } 

    const result: DirectionsData = {

        // result is non-null when the status is OK
        directions: response.result!,
        pickupLocation: pickup,
        dropoffLocation: dropoff,
    }

    return result;
}