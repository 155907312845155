import React from 'react';
import { InputAdornment, IconButton, Tooltip } from '@mui/material';
import Close from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

export interface TextFieldEndIconProps {

    /** Callback function called on click of the end icon */
    onClick?: () => void;

    /** Whether to display the clear icon in the input field. */
    showClearTextIcon: boolean;

    /** Whether to display the error icon in the input field. */
    showErrorIcon: boolean;
}

/**
 * Render the icon at the end of the text field
 */
export const TextFieldEndIcon = (props: TextFieldEndIconProps) => {

    if (props.showClearTextIcon) {
        return (
            <InputAdornment position="end">
                <Tooltip title="Clear" arrow>
                    <IconButton onClick={props.onClick} size="large">
                        <Close fontSize="small" />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        );
    }

    if (props.showErrorIcon) {
        return (
            <InputAdornment position="end">
                <IconButton size="large">
                    <ErrorIcon fontSize="small" color="error" />
                </IconButton>
            </InputAdornment>
        );
    }

    // do not show any icon if both props are false (e.g.: when the input field is empty and IsStrictValidationModeOn = false)
    return null;
}