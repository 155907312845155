import appstore from "../../../appStore";
import services from "../../../utils/service-vehicles/ServiceMetadata";
import { SelectVehicleByService } from "../../Condition/SelectVehicleByService";
import { Dispatch } from "../../Dispatch";
import { LocationIndex } from "../BookingEntities";

/** Operations to manage state related to the Taxi vs Parcel mode selector in the booking form. */
export namespace BookingFormParcelMode {

    /** Automatic state changes when the user selects the parcel mode widget. */
    export function SwapToParcel() {

        SelectVehicleByService(services.parcel);

        // clear contacts
        Dispatch.Verification.ClearContactNumberError();
        Dispatch.Booking.ClearContactName(LocationIndex.Pickup);
        Dispatch.Booking.ClearContactPhone(LocationIndex.Pickup);
        Dispatch.Booking.ClearContactName(LocationIndex.Dropoff);
        Dispatch.Booking.ClearContactPhone(LocationIndex.Dropoff);

        // reset validation
        Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);
    }

    /** Automatic state changes when the user changes the booking form to taxi mode. */
    export function SwapToTaxi() {

        // change the vehicle back to the default
        const vehicles = appstore.getState().condition.ConditionList;
        
        const defaultVehicle = vehicles.find(i => i.Service.IsDefault) || vehicles[0];
        Dispatch.Condition.SelectVehicle(defaultVehicle);

        // UI can't handle these
        ClearTaxiWidgetUnsupportedState();
    }

    /** 
     *  Reset any state that is supported by the parcel widget but not the taxi widget.
     *  Since the taxi widget doesn't show these fields, we can't keep it in Redux because the user would not expect it to appear in their booking.
     */
    export function ClearTaxiWidgetUnsupportedState() {

        // extended dropoff location
        Dispatch.Booking.ClearContactName(LocationIndex.Dropoff);
        Dispatch.Booking.ClearContactPhone(LocationIndex.Dropoff);
        Dispatch.Booking.ClearDriverNotes(LocationIndex.Dropoff);

        // delivery option
        Dispatch.Booking.ClearDeliveryOption();
        Dispatch.Booking.SetDeliveryOptionError(null);
    }
}