import { DeploymentEnvironment } from "../Entities/DeploymentEnvironment";
import { ConfigOverride } from "../Entities/ConfigOverride";

/**
 * Environment-specific overrides to the default config. 
 * The overrides may not exist for each environment (first Partial{}). 
 * An override may only override some of the settings (second Partial{}). 
 */
export const EnvironmentConfig: Partial<Record<DeploymentEnvironment, ConfigOverride>> = {};

/** overrides for production */
EnvironmentConfig[DeploymentEnvironment.Production] = {
    Values: {
        ApiBaseUrl: "https://invc-mgmt-api-prod.azurewebsites.net/",
        AppInsightsKey: "1c1307c3-b332-44a4-a33c-e7167cfeadec",
        IsTestModeSupported: false,
        Payment: {
            BrainTreeAuthKey: "production_ktw946tr_sb2td27y6dw23qfp",
            MpsEndpoint: "https://mps-api-management.azure-api.net/card-rego-service/site",
            PaymentFaqLink: "https://www.13cabs.com.au/app/faqs/"
        },
        StaticContentBaseUrl: "https://prod-13orange-cdn-endpoint.azureedge.net/",
        TrackingLinkBaseUrl: "https://book.13cabs.com.au/b/",
        Auth0: {
            ClientDomain: "13cabsorange.au.auth0.com",
            ClientId: "KmhVg2CWVXDpsfDKFw4o9WK5mL2fZynO",
            CustomDomain: "auth.13cabs.com.au",
            Connection: "13CABS-Public-Users"
        },
    },

    Features: {
        Dialog2Demo: false,
        MobileBookingFormUrl: false,
    },
}

/** 
 *  Overrides for local debug, i.e. code that runs from npm start.
 *  A common change might be to point the ApiBaseUrl to localhost.
 */
EnvironmentConfig[DeploymentEnvironment.LocalDebug] = {
    Values: {
        IsTestModeSupported: true,
        //ApiBaseUrl: 'http://localhost:64461/',

        /** "Booking Websites LocalHost Only" */
        GoogleMapsApiAuth: "key=AIzaSyCC0q_OHzPa0TA2mx9wFh35zqnnUXZcTSM",
    },

    Features: {
        UserFeatureFlags: true,
        DropGoogleTagManager: true
    }
}

/** These overrides apply to all Dev Environments */
EnvironmentConfig[DeploymentEnvironment.Development] = {
    Values: {
        /** "Booking Websites DevTest Not Localhost" */
        GoogleMapsApiAuth: "key=AIzaSyCESHkkfh1pnvJ_7CGcYUA7U4Z8RWEo3ug",
        TrackingLinkBaseUrl: "https://book-dev.13cabs.com.au/b/",
    },
}
