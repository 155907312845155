import { VehicleOption, MaxiTaxiLookUpType, MaxiTaxiSeaterInfoPayload } from "../Redux/ConditionEntities";
import React, { FC, useState, useEffect } from "react";
import { getContentUrl, ContentURL } from "../../Utils/ContentURL";
import { ApplicationState } from "../../../appState";
import { connect } from "react-redux";
import "./ConditionInfoView.scss";
import { Dispatch } from "../../Dispatch";
import { ConvertCentsToDollars } from "../../Fare/FareHelper";
import MaxiSeaterSelector from "../../../widgets/maxi-seater-selector/maxi-seater-selector";
import { CanProvidedConditionShowFixedFare, ShouldDisplayFare } from "../ShouldDisplayFare";
import { ValidateServicesForSatss } from "../ConditionValidation";
import { BookingWidgetModeKind } from "../../BookingTemplate/BookingTemplateEntities";

interface PropsFromStore {
    ActiveService: VehicleOption;
    ConditionList: VehicleOption[];
    MaxiTaxiLookUp: MaxiTaxiLookUpType;
    MaxiTaxiSeaterInfo: MaxiTaxiSeaterInfoPayload;
    SelectedCondition: VehicleOption;
    IsPriceGuaranteeSelected: boolean;
    BookingWidgetMode: BookingWidgetModeKind;
    CanShowFare: boolean;
}

/** Component to display more info about selected service. Opens as a full page popup. */
const ConditionInfoView: FC<PropsFromStore> = (props) => {

    const [NumberOfSeats, setNumberOfSeats] = useState(0);

    useEffect(() => {
        let selectedMaxiTaxiSeaterNo = props.MaxiTaxiSeaterInfo.Average;

        if (props.ActiveService.Service.isMaxiTaxi && props.SelectedCondition.Service.isMaxiTaxi) {
            selectedMaxiTaxiSeaterNo = props.SelectedCondition.ApiVehicle?.MaxSeat!;
        }

        if (selectedMaxiTaxiSeaterNo !== 0) {
            setNumberOfSeats(selectedMaxiTaxiSeaterNo);
        }
    }, [props.MaxiTaxiSeaterInfo, props.ActiveService]);

    /** Close the popup. */
    function CloseInfoView() {
        Dispatch.Condition.HideServiceInfo();
    }

    /** Update store on click of select button and hide the popup. */
    function SelectCondition() {
        let selectedVehicle = props.ActiveService;
        if (selectedVehicle.Service.isMaxiTaxi) {
            const condition = props.MaxiTaxiLookUp[NumberOfSeats];
            if (condition) {
                selectedVehicle = {
                    ...selectedVehicle,
                    Service: { ...selectedVehicle.Service, short: condition.Description },
                    ApiVehicle: condition
                };
            }
        }

        Dispatch.Condition.SelectVehicle(selectedVehicle);
        Dispatch.Condition.CollapseVehicleSelectorUI();
        Dispatch.Condition.HideServiceInfo();
        ValidateServicesForSatss();
    }

    /** Set/Update number of passengers for Maxi Taxi. */
    function SetMaxiTaxiSeatNo(numberOfSeats: number) {
        setNumberOfSeats(numberOfSeats);
    }

    const ActiveService = props.ActiveService;

    let fareConditionTitle = null;
    let fareConditionText = null;
    let fareAmount = "";
    let priceIconUrl = null;

    if (CanProvidedConditionShowFixedFare(props.IsPriceGuaranteeSelected, ActiveService) && ActiveService.FixedFare) {
        fareConditionTitle = "Price Guarantee";
        fareConditionText = "The price of your fare is based on the metered taxi rates and is inclusive of all extras and fees if you pay by card or other electronic means. If you pay by cash, the price charged by the driver may differ.";
        fareAmount = `$${ConvertCentsToDollars(ActiveService.FixedFare.FixedFareBreakdown.TotalAmount)}`;
        priceIconUrl = getContentUrl(ContentURL.images.Lock.OrangeLock);
    }
    else {
        fareConditionTitle = "Fare Estimates";
        fareConditionText = "Prices given are an estimate only based on kilometres travelled. Actual fare may vary due to traffic conditions, alternate routes, weather and unforeseen circumstances. Estimated fares do not include road tolls, airport fees or public holiday surcharges.";
        fareAmount = ActiveService.Fare || "";
    }

    const isTemplateModeOn = props.BookingWidgetMode !== BookingWidgetModeKind.Booking;
    
    return (
        <div className="service-info-panel">
            <div className="si-panel-content">
                <div className="si-panel-header">
                    <div className="si-back-section" onClick={CloseInfoView}>
                        <span><img src={getContentUrl(ContentURL.images.arrows.GreyLeftArrow)} className="si-back-btn" /></span>
                        <span className="si-back-text">Back</span>
                    </div>                    
                </div>
                <hr className="si-section-divider" />
                <div className="si-service-section">
                    <div className="si-service-header">
                        <div className="si-service-name">{ActiveService.Service.displayName}</div>                        
                        {!isTemplateModeOn && props.CanShowFare &&
                            <div className="si-fare">
                                {priceIconUrl && <img className="si-price-icon" src={priceIconUrl} />}
                                {fareAmount}
                            </div>
                        }
                    </div>
                    <div className="si-short-description">{ActiveService.Service.short}</div>
                    <div className="si-service-image">
                        <img src={ActiveService.Service.image} className="si-image" />
                    </div>
                    {props.ActiveService.RequiresPaxCount && <MaxiSeaterSelector
                        ClickedSeaterNo={NumberOfSeats}
                        MaxiTaxiSeaterInfo={props.MaxiTaxiSeaterInfo}
                        MaxiTaxiLookUp={props.MaxiTaxiLookUp}
                        reportMaxiTaxiSeaterNo={SetMaxiTaxiSeatNo}
                    />}
                </div>
                <div className="si-service-description">
                    <div className="si-service-description-inner">{ActiveService.Service.blurb}</div>
                </div>
                <div className="si-service-conditions">
                    <div className="si-fare-condition-title">{fareConditionTitle}<sup>*</sup></div>
                    <div className="si-fare-condition">{fareConditionText}</div>
                </div>
                <div className="si-select-service">
                    <button className="si-select-btn" onClick={SelectCondition}>Select This Service</button>
                </div>
            </div>                
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        ActiveService: state.condition.ActiveService!, // Guaranteed to be not null when this component is mounted.
        ConditionList: state.condition.ConditionList,
        MaxiTaxiLookUp: state.condition.MaxiTaxiLookUp,
        MaxiTaxiSeaterInfo: state.condition.MaxiTaxiSeaterInfo,
        SelectedCondition: state.condition.SelectedCondition,
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        CanShowFare: ShouldDisplayFare(state)
    }
}

export default connect(mapStateToProps)(ConditionInfoView);