import { GetValues } from "./Config/MyAppConfig";
import { createTheme } from "@mui/material";

export const theme = createTheme(({
    components: {
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === 'contained' &&
                ownerState.color === 'primary' && {
                  "&:hover":{
                    backgroundColor: GetValues().BrandColour,
                    color: "black"
                  }
                }),
            }),
          },
        },
    },
    typography: {
        fontFamily: [
            'Visby CF',
            'Arial'
        ].join(','),
        button: {
            textTransform: 'none',
            fontSize: '1rem'
        }
    },
    palette: {
        primary: {
            main: GetValues().BrandColour,
            contrastText: '#fff'
        },
        secondary: { 
            main: '#7ed321'        // Radio button checked green color
        },
        text: {
            primary: '#4a4a4a',    // Regular Text color
        },
        error: {
            main: '#b00020'
        }
    },
    spacing: 2
}));