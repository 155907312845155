import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { LocationIndex } from "../BookingEntities";
import NotesToDriver from "../PerLocation/NotesToDriver";

/** Consists of various driver notes fields based on the status/type of input values in booking address fields. */
const DriverNotesSectionCore: React.FC<ReduxProps> = (props) => {

    const separateNotesInputs = (
        <>
            <NotesToDriver Location={LocationIndex.Pickup} />
            <NotesToDriver Location={LocationIndex.Dropoff} />
        </>
    );

    if (props.IsPickupUsingFavourite) return separateNotesInputs;
    if (props.IsDropoffUsingFavourite) return separateNotesInputs;

    return <NotesToDriver Location={LocationIndex.Pickup} Combined />
}

// #region Redux

interface ReduxProps {

    IsPickupUsingFavourite: boolean;
    IsDropoffUsingFavourite: boolean;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        IsPickupUsingFavourite: !!state.booking.Pickup.Favourite,
        IsDropoffUsingFavourite: !!state.booking.Dropoff.Favourite,
    }
}

export const DriverNotesSection = connect(GetPropsFromRedux)(DriverNotesSectionCore);

// #endregion