import { LoginStatusKind, AuthToken } from './AuthEntities';
import appstore from "../../appStore";
import { TryAgainMessageKind } from "../Utils/ErrorMessages";
import { ShowDialogRetryErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import Auth from "../../modules/Authentication/AuthImpl";
import { Api } from '../../Services/Api';
import { Dispatch } from '../Dispatch';
import { LogoutKind } from "../../modules/Authentication/AuthEntities";
import { ParseAndStoreContactNumber } from '../Booking/NewBookingTabHelper';
import { LogEvent } from '../../utils/LogEvent';
import { SimpleUserProfile, SimpleUserProfileBase } from '../User/ProfileEntitiesV2';
import { GetUserMpsProfile } from '../Payment/PaymentHelper';
import { MyStorage } from '../../Storage';
import { LocationIndex } from '../Booking/BookingEntities';
import { OptionalUI } from '../Booking/OptionalParts/OptionalUI';

/**
 * The user has updated some detail of their profile, e.g. the phone number.
 * Reload it from the API.
 * However, we don't reload ancillary data like bookings or cards.
 */
export async function RefreshProfileAfterDataChange() {

    const profile = await TryLoadAndPersistProfile(true);
    if (!profile) {

        ShowDialogRetryErrorMessage(TryAgainMessageKind.Auth0LoginTryAgain);
        return;
    }

    // MPS profile is one thing that could be fixed by a profile data change, in particular when the phone number becomes non-null
    await GetUserMpsProfile(false);
}

/** 
 *  Try to restore the user profile data from a persisted login session that is now being resumed.
 *  Normally it will be in local storage along with our Auth token.
 *  Rarely (e.g. UserProfile V2 Upgrade) we will reload it from the API.
 */
export async function TryRestoreUserProfile(loginToken: AuthToken): Promise<SimpleUserProfile | null> {

    // ideal: get from storage
    const profile = MyStorage.UserProfileV2.LoadData();

    if (profile) {

        if (profile.TokenUserId === loginToken.sub!) {
            UpdateStoreWithUserData(profile);
            return profile;
        }
        else {
            LogEvent.LoginRestoreProfileMismatch(profile, loginToken);
            // don't use the persisted profile. Reload from API (below)
        }
    }

    // fallback: get from service, logout if failed
    const fromApi = await TryLoadAndPersistProfile(true);
    return fromApi;
}

/**
 * Loads the User Profile from the API and does minimal processing after that.
 * 1) API call to get data
 * 2) App insights success / failure logging
 * 3) Persist in local storage
 * 4) Push to Redux store.
 * If API call failed, logout only if specified by the caller
 */
export async function TryLoadAndPersistProfile(shouldLogoutOnFail: boolean): Promise<SimpleUserProfile | null> {

    Dispatch.Auth.LoginStatus(LoginStatusKind.GetUserProfileInProgress);

    const result = await Api.User.GetMyProfile();

    //early exit if the caller specified not to logout on failure. e.g.: when loading profile on page refresh.
    if (!result.isSuccess && !shouldLogoutOnFail) return null;

    // failure
    if (!result.isSuccess) {
        LogEvent.GetUserProfileFailure();
        new Auth().logout(LogoutKind.Website);
        return null;
    }

    // success
    const profile: SimpleUserProfile = CompleteProfile(result.value);

    MyStorage.UserProfileV2.StoreData(profile)
    LogEvent.GetUserProfileSuccess(profile);

    UpdateStoreWithUserData(profile);

    return profile;
}

/** 
 * Add TokenUserId to profile which is derived from GetUserProfileV2()
 */
function CompleteProfile(profile: SimpleUserProfileBase): SimpleUserProfile {
    const completeProfile: SimpleUserProfile = {
        ...profile,

        // the [sub] field is always defined for newly created tokens
        TokenUserId: appstore.getState().authentication.AuthToken!.sub!
    }

    return completeProfile;
}

/**
 * Various redux data updates after the user profile is loaded.
 */
function UpdateStoreWithUserData(userProfile: SimpleUserProfile): void {

    NoteUserLogin(userProfile);
    SetBookingFormDefaults(userProfile);
}

/**
 * Record the user as logged in in Redux.
 */
function NoteUserLogin(userProfile: SimpleUserProfile) {

    Dispatch.Auth.UserProfile(userProfile);
    Dispatch.Auth.LoginStatus(LoginStatusKind.LoggedIn);
}

/**
 * After loading the user profile, push some default values onto the booking form.
 */
function SetBookingFormDefaults(userProfile: SimpleUserProfile) {

    const appState = appstore.getState();

    // this is a safe default
    if (OptionalUI.CanBookOnAccount(appState)) {
        Dispatch.Booking.SetBookOnAccount(true);
    }

    // pickup contact usually matches the logged in user's details.
    // but don't overwrite if the user has already filled something in
    const passenger = appState.booking.Pickup.Contact;

    if (!passenger.Phone) {
        ParseAndStoreContactNumber(userProfile.ContactPhone);
    }
    
    if (!passenger.Name) {

        // company names don't make good contact names
        if (userProfile.CompanyId == null) {
            Dispatch.Booking.ContactName(LocationIndex.Pickup, userProfile.DisplayName);
        }
    }
}
