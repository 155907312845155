import React from "react";
import { GoogleMapsEnhance } from "./GooglePropsEnhancer";

/** Not 100% sure I need this. Is this what React.Component is doing by default? */
class JustRenderChildren extends React.Component {

    render() {
        return this.props.children;
    }
}

/** Loads the google maps library, then renders children when it is available */
export const WaitForGoogleMaps = GoogleMapsEnhance(JustRenderChildren);