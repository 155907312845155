import React from "react";
import "./AccountBookings.scss";
import { AccountBookingPayload } from "../BookingEntities";
import { Dispatch } from "../../Dispatch";
import { OutlinedTextField, CustomTextFieldProps } from "../Widget/OutlinedTextField";

interface DVAState {
    isFileNumberFocused: boolean;
    isProviderNumberFocused: boolean;
}

interface DVAAccountProps {
    isBookingButtonClicked : boolean;
    AccountBookingDetails: AccountBookingPayload | null;
}

interface DVAMandatoryFields {
    isValidFileNumber : boolean;
    isValidProviderNumber : boolean;   
}

/** Custom fields only for DVA accounts */
class DVACustomFields extends React.Component<DVAAccountProps, DVAState> {
    
    private inputFileRef: React.RefObject<HTMLInputElement>;
    private inputProviderRef: React.RefObject<HTMLInputElement>;
    
    constructor(props: DVAAccountProps) {
        super(props);

        this.state = {
            isProviderNumberFocused: false,
            isFileNumberFocused: false
        }

        this.inputFileRef = React.createRef();
        this.inputProviderRef = React.createRef();        
        this.updateBookingData = this.updateBookingData.bind(this);
    }

    /**
     * Update the Order Number and File Number, when booking from recent trips    
     */
    componentDidMount() {
        
        if (!this.props.AccountBookingDetails) return;

        const { OrderNumber = "", FileNumber } = this.props.AccountBookingDetails;

        if (this.inputProviderRef.current) {
            this.inputProviderRef.current.value = OrderNumber;
        }

        if (FileNumber && this.inputFileRef.current) {
            this.inputFileRef.current.value = FileNumber;
        }
    }
    
    /**
     * Update the Order Number and File Number, on account selection in the dropdown
     */
    UNSAFE_componentWillReceiveProps(nextProps : DVAAccountProps) {        

        if (!nextProps.AccountBookingDetails) return;

        const { OrderNumber = "", FileNumber } = nextProps.AccountBookingDetails;

        if (this.inputProviderRef.current && this.inputProviderRef.current.value !== OrderNumber) {
            this.inputProviderRef.current.value = OrderNumber;
        }

        if (FileNumber === "" && this.inputFileRef.current && this.inputFileRef.current.value !== FileNumber) {
            this.inputFileRef.current.value = FileNumber;
        }
    }

    updateBookingData(e: React.FocusEvent<HTMLInputElement>){        
        const { name, value } = e.target;
        const text = value ? value.trim() : '';
        Dispatch.Booking.AccountDetails({...this.props.AccountBookingDetails!, [name]: text});

        // Remove focus from the input field
        if(name === "OrderNumber") {
            this.updateProviderNumberFocusStatus(false);
        }
        else if(name === "FileNumber") {
            this.updateFileNumberFocusStatus(false);
        } 
    }

    /** Set focus status for provide number */
    updateProviderNumberFocusStatus = (value: boolean) => this.setState({ isProviderNumberFocused: value });      
    
    /** Set focus status for file number */
    updateFileNumberFocusStatus = (value: boolean) => this.setState({ isFileNumberFocused: value });

    /** Update empty value for provider number in store */
    clearProviderNumberText = () => Dispatch.Booking.AccountDetails({...this.props.AccountBookingDetails!, OrderNumber: ''});

    /** Update empty value for file number in store */
    clearFileNumberText = () => Dispatch.Booking.AccountDetails({...this.props.AccountBookingDetails!, FileNumber: ''});

    validateInputfields = () : DVAMandatoryFields => {
                
        let isValidFileNumber = true;   // true indicates valid input
        let isValidProviderNumber = true;
        
        const { isBookingButtonClicked, AccountBookingDetails } = this.props;
        
        if (isBookingButtonClicked && AccountBookingDetails) {
            
            const { OrderNumber, FileNumber } = AccountBookingDetails;

            isValidProviderNumber = this.state.isProviderNumberFocused || OrderNumber !== "";

            isValidFileNumber = this.state.isFileNumberFocused || !!FileNumber;
        }

        return {
            isValidFileNumber,
            isValidProviderNumber
        };
    }

    render() {
        
        const { isProviderNumberFocused, isFileNumberFocused } = this.state;

        // Validate DVA fields
        const { isValidFileNumber, isValidProviderNumber } = this.validateInputfields();

        const errorTextForProviderNumber = "Provider number is required";

        const errorTextForFileNumber = "File number is required";

        const inputPropForProviderNumber: CustomTextFieldProps  = {
            Name: "OrderNumber",
            AllowAutoFocus: true,
            LabelText: "Provider Number",
            onBlurEvent: this.updateBookingData,
            IsInputInvalid: !isValidProviderNumber,
            ErrorMessage: errorTextForProviderNumber,
            IsInputFocussed: isProviderNumberFocused,
            onClearEvent: this.clearProviderNumberText,
            onFocusEvent: this.updateProviderNumberFocusStatus,
            DoesInputHasValue: !!this.props.AccountBookingDetails?.OrderNumber
        }

        const inputPropForFileNumber: CustomTextFieldProps  = {
            Name: "FileNumber",
            LabelText: "DVA File Number",
            onBlurEvent: this.updateBookingData,
            ErrorMessage: errorTextForFileNumber,
            IsInputInvalid: !isValidFileNumber,
            IsInputFocussed: isFileNumberFocused,
            onClearEvent: this.clearFileNumberText,
            onFocusEvent: this.updateFileNumberFocusStatus,
            DoesInputHasValue: !!this.props.AccountBookingDetails?.FileNumber
        }
        
        return (
            <div className="dva-custom-fields">
                
                <OutlinedTextField ref={this.inputProviderRef} {...inputPropForProviderNumber} />
                
                <div className="dva-input-group">                  
                    <OutlinedTextField ref={this.inputFileRef} {...inputPropForFileNumber} />
                </div>
            </div>         
        )
    }
}

export default DVACustomFields;