import React, { Component } from 'react';
import { getContentUrl, ContentURL } from '../../../modules/Utils/ContentURL';
import './selected-vehicle-bar.scss';
import services, { ServiceKind } from '../../../utils/service-vehicles/ServiceMetadata';
import { VehicleOption, DataLoadingStatus } from '../Redux/ConditionEntities';
import { ConvertCentsToDollars } from '../../Fare/FareHelper';
import { Dispatch } from '../../Dispatch';
import "./ConditionView.scss";
import { ApplicationState } from '../../../appState';
import { connect } from 'react-redux';
import { ShouldDisplayFare, CanProvidedConditionShowFixedFare } from '../ShouldDisplayFare';
import { Tooltip } from '@mui/material';

interface VehicleBarProps {
    ServiceDetails: VehicleOption;
    IsTemplateModeOn: boolean;
    FareLoadStatus?: DataLoadingStatus;
    ClickHandler: Function;

    /** Used to enable specific styles to the last element of the list. */
    IsLastElement?: boolean;
}

interface PropsFromStore {
    SelectedCondition: VehicleOption;
    IsVehicleSelectorActive: boolean;
    CanShowFare: boolean;
    IsPriceGuaranteeSelected: boolean;
}

/** Component to display basic condition details. This component is included in different other components. */
class ConditionView extends Component<VehicleBarProps & PropsFromStore> {
    constructor(props: VehicleBarProps & PropsFromStore) {
        super(props)

        this.OpenServiceInfo = this.OpenServiceInfo.bind(this);
    }

    /** Open the popup to display more info about the service. */
    OpenServiceInfo(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        Dispatch.Condition.ActiveService(this.props.ServiceDetails);
        Dispatch.Condition.ShowServiceInfo();
    }

    /** Derives the short description of the service (to be displayed below the service name). */
    DeriveShortDescription(): string {
        if (this.props.ServiceDetails.Service.kind === ServiceKind.Parcel) return "Fits in a car";
        if (this.props.ServiceDetails.Service.kind === ServiceKind.MaxiTaxi) {
            if (this.props.SelectedCondition.ApiVehicle) {
                if (this.props.SelectedCondition.Service.kind === ServiceKind.MaxiTaxi) {
                    return this.props.SelectedCondition.ApiVehicle.Description;
                }
            }
        }

        return this.props.ServiceDetails.Service.short;
    }

    /** Decides whether the current vehicle is the selected vehicle in order to do custom CSS. 
     * Not applicable when the vehicle selector is collapsed. */
    IsSelectedService(): boolean {
        if (!this.props.IsVehicleSelectorActive) return false;

        // for V1 API vehicles
        if (this.props.ServiceDetails.Service.kind && this.props.SelectedCondition.Service.kind === this.props.ServiceDetails.Service.kind) return true;

        // for V2 API vehicles
        if (this.props.SelectedCondition.ApiVehicle?.ApiId === this.props.ServiceDetails.ApiVehicle?.ApiId) return true;

        return false;
    }

    render() {
        const fare = this.props.CanShowFare && this.props.ServiceDetails.Fare !== undefined ? this.props.ServiceDetails.Fare : "Dest required";
        const vehicleImg = this.props.ServiceDetails.Service.isAny === false ? this.props.ServiceDetails.Service.image : services.sedan.image;
        const parcelImageClass = this.props.ServiceDetails.Service.kind === ServiceKind.Parcel && "selected-parcel-image";

        let displayFare = fare;

        const shouldShowFixedFare = CanProvidedConditionShowFixedFare(this.props.IsPriceGuaranteeSelected, this.props.ServiceDetails);

        // Display fixed fare if available.
        if (shouldShowFixedFare && this.props.ServiceDetails.FixedFare) {
            displayFare = `$${ConvertCentsToDollars(this.props.ServiceDetails.FixedFare.FixedFareBreakdown.TotalAmount)}`;
        }

        const fareLabel = shouldShowFixedFare ? "Price Guarantee" : "Fare Estimate";

        const shortDescription = this.DeriveShortDescription();

        let serviceContainerClass = this.IsSelectedService() ? "mobile-condition-container highlight-selected-service" : "mobile-condition-container";

        if (!this.props.IsVehicleSelectorActive || this.props.IsLastElement) serviceContainerClass = `${serviceContainerClass} full-bottom-border`;

        const serviceInfo = <div className="font_visby">
            <span className="bar-info-row-title-1">{this.props.ServiceDetails.Service.displayName}</span>
            <span className="bar-info-row-1 font_helvetica">{shortDescription}</span>
        </div>;

        return (
            <div className={serviceContainerClass} onClick={() => this.props.ClickHandler()}>
                <div className="mobile-condition-content">
                    <div className={`selected-vehicle-bar-image ${parcelImageClass}`}><img src={vehicleImg} alt={this.props.ServiceDetails.Service.displayName} /></div>
                    {serviceInfo}
                    <Tooltip title="View information about this service" arrow>
                        <div className="service-info-block" onClick={this.OpenServiceInfo}><img src={getContentUrl(ContentURL.images.info.GreyInfoIcon)} className="service-info-icon" /></div>
                    </Tooltip>
                    {
                        !this.props.IsTemplateModeOn && <div className="bar-margin-left font_helvetica">
                            <span className="fe-title">{fareLabel}</span>
                            <p className="fe-value">
                                {shouldShowFixedFare && <img className="fe-lock" src={getContentUrl(ContentURL.images.Lock.OrangeLock)} /> }
                                <span>{displayFare}</span>
                            </p>
                        </div>
                    }
                    {
                        this.props.FareLoadStatus === DataLoadingStatus.InProgress && <img src={getContentUrl(ContentURL.images.Loading)} alt="Loading" className="fare-loading" />

                    }
                </div>                
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        CanShowFare: ShouldDisplayFare(state),
        SelectedCondition: state.condition.SelectedCondition,
        IsVehicleSelectorActive: state.condition.IsVehicleSelectionActive,
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected
    };
}

export default connect(mapStateToProps)(ConditionView);