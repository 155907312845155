import React from "react";
import { LocationIndex } from "../../Booking/BookingEntities";
import { AddressDriverNotes } from "./AddressDriverNotes";
import { AddressInput } from "./AddressInput";
import { AddressPointActions } from "./AddressPointActions";
import { AddressPointName } from "./AddressPointName";
import { GoogleMapForAddress } from "./GoogleMapForAddress";

export const CreateOrEditAddressPoint: React.FC<{}> = () => {

    return (
        <div className="address-points-dialog-main-panel">
            <GoogleMapForAddress />
            <AddressInput />
            <AddressPointName />
            <AddressDriverNotes Location={LocationIndex.Pickup} />
            <AddressDriverNotes Location={LocationIndex.Dropoff} />
            <AddressPointActions />
        </div>
        );
}