import React from "react";
import "./BookingAddressComponent.scss";
import { connect } from "react-redux";
import { ApplicationState } from '../../../appState';
import { GeoPoint } from "../../Location/Entities";
import { getContentUrl, ContentURL } from '../../Utils/ContentURL';
import { PickupAddressStatus } from "../PickupAddressStatus";
import { ServiceCheckStatus, PickupServiceCheckState, LocationIndex } from "../BookingEntities";
import { SimpleUserProfile } from "../../User/ProfileEntitiesV2";
import { MapView } from "../../../widgets/NavBar/TabEntities";
import { Dispatch } from "../../Dispatch";
import { ConvertToPlaceResult, ApplyPickupLocationSelection, ApplyDropoffLocationSelection } from '../BookingLocationHelper';
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { VehicleOption } from "../../Condition/Redux/ConditionEntities";
import { AddressStatusType, BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import { GetBrandedUrl, BrandedImage } from "../../Utils/BrandedContentUrls";
import { CustomErrorMessages, WellKnownMessageKind } from "../../Utils/ErrorMessages";
import { ServiceKind } from "../../../utils/service-vehicles/ServiceMetadata";
import { AddressV2 } from "../../../Services/MakeBookingContracts";
import { GoogleAddressPicker } from "../../AddressPicker/Components/GoogleAddressPicker";
import { LocationTypes } from "../../AddressPicker/Entities/PlaceSearchEntities";
import { AddressEntryKind, AddressPickerEntry } from "../../AddressPicker/Entities/AddressPickerEntry";
import { ConsiderClearingDriverNotesFromFavourite } from "../ConsiderClearingDriverNotesFromFavourite";

interface PropsFromStore {
    UserProfile : SimpleUserProfile | undefined,
    BookOnAccount: boolean,
    locationVicinity: GeoPoint;
    placeTextPickup: string;
    placeTextDropoff: string;
    PickupServiceCheckStatus: string;
    IsBookingFormStrictValidationModeOn: boolean;

    /** Low level UI state (focused, valid) for the pickup address */
    PickupUiState: AddressStatusType;

    /** Low level UI state (focused, valid) for the dropoff address */
    DropoffUiState: AddressStatusType;
    SelectedVehicle: VehicleOption,
    BookingWidgetMode: BookingWidgetModeKind;
    PickupServiceCheck : PickupServiceCheckState;
    PickupV2: AddressV2 | null;
    DropoffV2: AddressV2 | null;

    ActiveBookingForm: BookingFormKind;
}

interface AddressProps { 
    IsPickup: boolean;
}

class BookingAddressComponent extends React.Component<PropsFromStore & AddressProps> {
    constructor(props: PropsFromStore & AddressProps) {
        super(props);
    }

    /** Quick clear */
    onClearEvent = () => {   
        if (this.props.IsPickup) {
            Dispatch.GoogleMap.PickupCleared();
            Dispatch.Booking.ClearAddress(LocationIndex.Pickup);

            Dispatch.Booking.PickupServiceability({
                PickupPlaceId: "",
                ServiceabilityCheckState: {
                    status: ServiceCheckStatus.NoInputSelected
                }
            });

            Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(false);
            Dispatch.UILogicControl.ValidateVehicleOnPickupChange(true);
            ConsiderClearingDriverNotesFromFavourite(true);
            Dispatch.Booking.ClearFavouriteAddress(LocationIndex.Pickup);
        } else {
            Dispatch.GoogleMap.DropoffCleared();
            Dispatch.Booking.ClearAddress(LocationIndex.Dropoff);
            Dispatch.UILogicControl.OnDoesDropoffInputHaveValueChange(false);
            ConsiderClearingDriverNotesFromFavourite(false);
            Dispatch.Booking.ClearFavouriteAddress(LocationIndex.Dropoff);
        }
        Dispatch.Condition.ClearPriceGuarantee();
        Dispatch.Condition.ClearFareEstimate();
    }

    onPlaceSelected = async (place: google.maps.places.PlaceResult, placeText: string, addressEntry: AddressPickerEntry) => {

        // new Google typings have raised the possibility of getting blanks from these values
        // these are places we cannot handle
        if (!place.geometry) return;
        if (!place.place_id) return;

        const placeResult = ConvertToPlaceResult(place, placeText);

        if (this.props.IsPickup) {
            // Remove not valid error message if the user enters an address.
            Dispatch.UILogicControl.SetPickupValidity(true);
            ApplyPickupLocationSelection(placeResult);

            // Update or clear existing booking favourite address based on the AddressEntry type.
            if (addressEntry.Kind === AddressEntryKind.GoogleMaps) {
                ConsiderClearingDriverNotesFromFavourite(true);
                Dispatch.Booking.ClearFavouriteAddress(LocationIndex.Pickup);
            }
            else {
                Dispatch.Booking.FavouriteAddress(LocationIndex.Pickup,  addressEntry.Favourite);

                if (addressEntry.Favourite.PickupNotes) {
                    Dispatch.Booking.DriverNotes(LocationIndex.Pickup, addressEntry.Favourite.PickupNotes);
                }
            }
        } else {
            Dispatch.UILogicControl.SetDropoffValidity(true);
            ApplyDropoffLocationSelection(placeResult);

            if (addressEntry.Kind === AddressEntryKind.GoogleMaps) {
                ConsiderClearingDriverNotesFromFavourite(false);
                Dispatch.Booking.ClearFavouriteAddress(LocationIndex.Dropoff);
            }
            else {
                Dispatch.Booking.FavouriteAddress(LocationIndex.Dropoff,  addressEntry.Favourite);
                if (addressEntry.Favourite.DropoffNotes) {
                    Dispatch.Booking.DriverNotes(LocationIndex.Dropoff, addressEntry.Favourite.DropoffNotes);
                }
            }
        }        
    }    
    
    IsPickUpInputValid = () => {  

        const { PickupV2, BookingWidgetMode, PickupServiceCheckStatus, IsBookingFormStrictValidationModeOn, PickupUiState } = this.props;
        
        // Should select serviceable address
        if (PickupServiceCheckStatus === ServiceCheckStatus.KnownBad) return false;

        // Address can't be populated from an existing booking
        if (!PickupUiState.IsValid) return false;

        // Validate in strict validation mode
        if (!IsBookingFormStrictValidationModeOn) return true; 
        
        // Do not validate, if pickup input is focused
        if (PickupUiState.IsFocus) return true;
        
        // Validate input value for template booking
        if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {
            
            // Invalid state if input is entered but, no address is selected
            if (PickupUiState.DoesInputHaveValue && !PickupV2) return false;            
        }
        else {
            // Pickup address is mandatory for booking only
            if (PickupServiceCheckStatus !== ServiceCheckStatus.KnownGood) return false;
        }       
                               
        return true;
    }
     
    IsDropOffInputValid = () => {

        const { DropoffV2, UserProfile, BookOnAccount, IsBookingFormStrictValidationModeOn, BookingWidgetMode, DropoffUiState } = this.props;

        // Cannot populate from an existing booking
        if (!DropoffUiState.IsValid) return false;

        // Validate in strict validation mode
        if (!IsBookingFormStrictValidationModeOn) return true;            
        
        // Do not validate, if dropoff input is focused
        if (DropoffUiState.IsFocus) return true;
        
        // Valid Dropoff is selected
        if (DropoffV2) return true;
        
        // Validate input value for template booking
        if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {
            
            // Invalid state if the input is entered but, address is not selected
            if (!DropoffUiState.DoesInputHaveValue) return true;
        }
        else {

            const isParcelBooking = this.props.SelectedVehicle.Service.kind === ServiceKind.Parcel;

            const isBookingOnAccount = UserProfile != null && BookOnAccount;

            // Validate for account and parcel booking
            if (!isBookingOnAccount && !isParcelBooking) return true;
        };

        return false;
    }

     /**
     * There are only 2 situations show message:
     * 1> No pick when strict validation mode === true;
     * 2> Not enough details for pickup;
     */
    decidePickupErrorMessage = () => {

        const { PickupServiceCheck, IsBookingFormStrictValidationModeOn, BookingWidgetMode, PickupUiState } = this.props;
        
        // In strict validation mode, error message is displayed if Pickup address is empty or invalid
        if (IsBookingFormStrictValidationModeOn) {
            
            const noPickupSelected = PickupServiceCheck.status === ServiceCheckStatus.NoInputSelected;

            // Validate input value for template booking
            if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {

                const isPickupInvalid = !PickupUiState.IsFocus && PickupUiState.DoesInputHaveValue && noPickupSelected;

                // Invalid pickup address if input is entered but, no address is selected
                if (isPickupInvalid) return CustomErrorMessages.NoPickup;
                
                return "";// All good
            }

            if(noPickupSelected) return CustomErrorMessages.NoPickup;   
        }

        if (PickupServiceCheck.status === ServiceCheckStatus.KnownBad && PickupServiceCheck.errorMessage.ProblemText === WellKnownMessageKind.StreetNameUnavailable.ProblemText && PickupServiceCheck.isPickupErrorMessageShown)
            return PickupServiceCheck.errorMessage.SolutionText;

        // Pickup address can't be populated from history/favourite
        if (!PickupUiState.IsValid) return CustomErrorMessages.NoPickup;

        return ""; // Valid
    }

    decideDropoffErrorMessage = () => {

        const { DropoffV2, BookOnAccount, IsBookingFormStrictValidationModeOn, BookingWidgetMode, DropoffUiState } = this.props; 

         /** In strict validation mode, error message is displayed if any of the below conditions are satisfied:
         * 1) When booking on accounts, Dropoff address is empty or invalid
         * 2) For parcel booking, Dropoff address is empty or invalid
         */
        if (IsBookingFormStrictValidationModeOn) {
            
            // Validate input value for template booking
            if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {

                const isDropoffInvalid = !DropoffUiState.IsFocus && DropoffUiState.DoesInputHaveValue && !DropoffV2;

                // Invalid dropoff address if input is entered but, no address is selected
                if (isDropoffInvalid) return CustomErrorMessages.NoDropoff;

                return "";// All good
            }

            const isParcelBooking = this.props.SelectedVehicle.Service.kind === ServiceKind.Parcel;
            const isDropoffRequired = BookOnAccount || isParcelBooking;

            if (isDropoffRequired && !DropoffV2) return CustomErrorMessages.NoDropoff;
        }
        
        // Dropoff address can't be populated from history/favourite
        if (!DropoffUiState.IsValid) return CustomErrorMessages.NoDropoff;

        return "";// All good
    }

    /** Change to MapView on click of the field or on start of entering value. */
    changeView = () => Dispatch.Tab.SelectItem(MapView);

    render() {

        const isBookingOnAccount = this.props.UserProfile != null && this.props.BookOnAccount;
        
        const isBookingModeOn = this.props.BookingWidgetMode === BookingWidgetModeKind.Booking;
        
        /**
         * Set focus on Pickup address input when,
         * 1. Not booking on accounts
         * 2. Not on Booking template mode
         */
        const allowFocus = isBookingModeOn && !isBookingOnAccount && this.props.IsPickup;

        const errorMessage = this.props.IsPickup ? this.decidePickupErrorMessage() : this.decideDropoffErrorMessage();

        // Drop-off (default)
        let placeText = this.props.placeTextDropoff;  
        let addressIcon = getContentUrl(ContentURL.images.address.b);
        let labelText = this.props.ActiveBookingForm === BookingFormKind.PassengerBooking ? "Add destination" : "Add dropoff";
        let isAddressInvalid = !this.IsDropOffInputValid();

        if (this.props.IsPickup) {
            labelText = "Add pickup";
            placeText = this.props.placeTextPickup;

            isAddressInvalid = !this.IsPickUpInputValid();
            addressIcon = GetBrandedUrl(BrandedImage.PickupAddressA);
        }

        return (
            <div className="booking-fields-panel">
                <div className="booking-address-field" onClick={this.changeView} onKeyDown={this.changeView}>

                    <GoogleAddressPicker
                        LocationType={LocationTypes.Address}
                        OnPlaceSelected={this.onPlaceSelected}
                        LabelText={labelText}
                        PreferNearbyTo={this.props.locationVicinity}
                        SpecifiedPlaceText={placeText}
                        OnCleared={this.onClearEvent}
                        StartIconUrl={addressIcon}
                        IsSelectedAddressInvalid={isAddressInvalid}
                        AutoFocus={allowFocus}
                        IncludeFavouriteAddresses={true}
                    />
                
                    { this.props.IsPickup && <PickupAddressStatus/> }                
                    { errorMessage && <div className="booking-address-message">{errorMessage}</div> }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        UserProfile: state.authentication.UserProfile,
        BookOnAccount: state.booking.IsOnAccount,
        locationVicinity: state.location.reliableLocation.value.geoPoint,
        placeTextPickup: CalculateAddressDisplayText(state.booking.Pickup.Address),
        placeTextDropoff: CalculateAddressDisplayText(state.booking.Dropoff.Address),
        PickupServiceCheckStatus: state.booking.PickupServiceCheck.status,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        PickupV2: state.booking.Pickup.Address,
        DropoffV2: state.booking.Dropoff.Address,
        PickupUiState: state.uiLogicControl.AddressStatus.Pickup,
        DropoffUiState: state.uiLogicControl.AddressStatus.Dropoff,
        SelectedVehicle: state.condition.SelectedCondition,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        PickupServiceCheck: state.booking.PickupServiceCheck,
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm 
    };
}

function CalculateAddressDisplayText(address: AddressV2 | null): string {

    if (!address) return "";

    if (!address.FullTextAddress) return "";

    return address.FullTextAddress;
}

export default connect(mapStateToProps)(BookingAddressComponent);