import * as React from 'react';
import { ContactFormFields, ContactDetailState  } from './ContactFormEntities';
import { SendContactRequest, SendContactRequestBooking, SendContactRequestHail } from './ContactFormEntities';
import { isNumber } from '../../utils/Formattingutil';
import ContactCategDetail from './ContactCategoryDetails';
import './ContactForm.scss';
import { ContactUsState } from './ContactFormEntities';
import ContactFormInputs from './ContactFormInputs';
import { CategoryDefinition } from './ContactFormCategories';
import { LocationData } from '../Location/Entities';
import { ApplicationState } from '../../appState';
import { ContactFormData } from './ContactFormData';
import { connect } from 'react-redux';
import { Api } from '../../Services/Api';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ReactNode } from 'react';
import { MobileDialog } from '../Dialog/MobileDialog';
import { UILayoutMode } from '../UILogicControl/UILogicControlEntities';

interface PropsFromStore {
    IsMobileDevice: boolean;
    preferredLocation: LocationData;
}

/** Main component of the contact form. */
class ContactForm extends React.Component<PropsFromStore, ContactUsState> {
    selectCategoryOption: ReactNode;

    constructor(props: PropsFromStore) {
        super(props);
        this.receiveState = this.receiveState.bind(this);
        this.state = {
            selectedSection: 0,
            selectedCategory: 0,
            selectedsubCategoryDetail: 0,
            selectedsubCategory2Detail: 0,
            selectedsubCategory3Detail: 0,
        };

        this.updateSection = this.updateSection.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
    }

    // this type is really complex, too hard to describe. 
    public updateSection(newValue: any) {
        this.setState(
            {
                selectedSection: (newValue.target && newValue.target.value) || 0,
                selectedCategory: 0,
                selectedsubCategoryDetail: 0,
                selectedsubCategory2Detail: 0,
                selectedsubCategory3Detail: 0,
            });
    }

    public updateCategory(newValue: any) {
        this.setState({
            selectedCategory: (newValue.target && newValue.target.value) || 0,
            selectedsubCategoryDetail: 0,
            selectedsubCategory2Detail: 0,
            selectedsubCategory3Detail: 0,
        });
    }

    public render() {
        // Get category data (dropdown options)
        const content = ContactFormData;
        const current = content[this.state.selectedSection || 0];
        const selectOptions = content.map((entry, index) => {
            return <MenuItem value={index} key={index}>{entry.section}</MenuItem>
        })

        const labelText =  !!selectOptions ? "I'm a" : "";
        
        return (
            <div className="contact-us-form">
                <div className="contact-form-step">
                    <div className="step-data">
                        <div>
                            <span className="step-number">Step 1/3</span>
                            <h1 className="contact-title">How can we help</h1>
                        </div>
                        <p className="contact-us-description">Tell us about your enquiry.</p>
                        <p className="contact-form-info">Please select below (required)</p>
                        <div className="section-selection reason">
                            <FormControl variant="outlined" className="custom-select" fullWidth={true}>
                                <InputLabel id="section-selection-label">{labelText}</InputLabel>
                                <Select
                                    label={labelText}
                                    value={this.state.selectedSection}
                                    onChange={this.updateSection}
                                    autoFocus
                                >
                                    {selectOptions}
                                </Select>
                            </FormControl>
                        </div>
                        {this.getSubHeadSelection(current)}
                        <ContactCategDetail
                            currentData={current}
                            receiveState={this.receiveState}
                            selectedCategory={this.state.selectedCategory || 0}
                            selectedSubCategory={this.state.selectedsubCategoryDetail || 0}
                            selectedSubCategory2={this.state.selectedsubCategory2Detail || 0}
                            selectedSubCategory3={this.state.selectedsubCategory3Detail || 0} />
                    </div>
                    <div className="step-padding"></div>
                </div>

                <ContactFormInputs
                    locationData={this.props.preferredLocation}
                    currentData={current}
                    selectedCategories={this.state}
                    onSubmit={(formData: ContactFormFields) => this.onSubmit(formData, current)} />

                { this.props.IsMobileDevice && <MobileDialog /> }
            </div>
        );
    }

    // Dropdown for the enquiry type.
    private getSubHeadSelection(contactEntry: CategoryDefinition) {
        if (!contactEntry.subCategories || contactEntry.subCategories.length <= 0) {
            return null;
        }

        const categorySelection = contactEntry.subCategories.map((categories, index) => {
            return <MenuItem value={index} key={index}>{categories.label}</MenuItem>
        })

        const labelText =  !!categorySelection ? "Enquiry type" : "";

        return <div className="section-selection categories">
            <FormControl variant="outlined" className="custom-select" fullWidth={true} >
                <InputLabel id="category-selection-label">{labelText}</InputLabel>
                <Select
                    autoFocus
                    label = {labelText}
                    value={this.state.selectedCategory}
                    onChange={this.updateCategory}
                >
                {categorySelection}
                </Select>
            </FormControl>
        </div>;
    }

    /**
     * Format pickup date-time.
     */
    private getPickupDate(form: ContactFormFields): string {
        
        if (form.pickupDateTime) {
            return form.pickupDateTime.toISO();
        }

        return '';
    }

    /**
     * Get selected catogories from another component
     */
    private receiveState(contactDetailState: ContactDetailState): any {
        this.setState({
            selectedsubCategoryDetail: contactDetailState.selectedsubCategory,
            selectedsubCategory2Detail: contactDetailState.selectedsubCategory2,
            selectedsubCategory3Detail: contactDetailState.selectedsubCategory3
        });
    }

    /**
     * Prepare request data and send the request.
     */
    private onSubmit(form: ContactFormFields, contactEntry: CategoryDefinition) {
        let subCategory: string = '';
        let subCategory2: string = '';
        let subCategory3: string = '';
        let subCategory4: string = '';
        let request: SendContactRequest;
        const pickupDate = this.getPickupDate(form);
        const fleetType = (isNumber(form.fleetType) && contactEntry.fleetTypes) ? contactEntry.fleetTypes[form.fleetType].label : '';
        if (isNumber(this.state.selectedCategory) && contactEntry.subCategories) {
            const category = contactEntry.subCategories[this.state.selectedCategory];
            subCategory = category.label;
            const category2 = category.subCategory ? category.subCategory[this.state.selectedsubCategoryDetail] : { label: '', subCategory2: [{ label: '' }] };
            subCategory2 = category2.label;
            const category3 = category2.subCategory2 ? category2.subCategory2[this.state.selectedsubCategory2Detail] : { label: '', subCategory3: [{ label: '' }] };
            subCategory3 = category3.label;
            const category4 = category3.subCategory3 ? category3.subCategory3[this.state.selectedsubCategory3Detail] : { label: '' };
            subCategory4 = category4.label;

            request = {
                city: this.GetBestCityName(),
                state: this.props.preferredLocation.isKnown ? this.props.preferredLocation.value.stateCode : "vic",
                userType: contactEntry.label,
                categorisation: [subCategory, subCategory2, subCategory3, subCategory4],
                notes: form.itemDescription,
                contact: {
                    email: form.emailAddress,
                    firstName: form.firstName,
                    lastName: form.lastName,
                    phone: form.custContactNumber,
                },
                source: "DesktopWeb"
            };

            if (((subCategory === 'Complaint' && subCategory2 !== 'Other') || subCategory === 'Trip Receipt' || subCategory === 'Lost Property') && form.selectedBookingHail === 'booking') {
                (request as SendContactRequestBooking).booking = {
                    reference: form.bookingRef,
                    name: form.bookingName,
                    phoneNumber: form.driverNumber,
                    jobDate: pickupDate,
                    driver: form.driverId,
                    fleet: fleetType,
                    taxi: form.cabNumber,
                    accountName: form.corporateAccountName,
                    accountNumber: form.corporateAccountNumber,
                };
            } else if (((subCategory === 'Complaint' && subCategory2 !== 'Other') || subCategory === 'Trip Receipt' || subCategory === 'Lost Property') && form.selectedBookingHail === 'hail') {
                (request as SendContactRequestHail).hail = {
                    jobDate: pickupDate,
                    fare: Number.parseFloat(form.fareAmount),
                    driver: form.driverId,
                    fleet: fleetType,
                    taxi: form.cabNumber,
                    pickup: { suburb: form.pickup.suburb, street: form.pickup.street },
                    destination: { suburb: form.destination.suburb, street: form.destination.street },
                    accountName: form.corporateAccountName,
                    accountNumber: form.corporateAccountNumber,
                };
            }
            return Api.Contact.SubmitEnquiry(request);
        } else {
            return Promise.reject('Please select some categories');
        }
    }

    /**
     * If preferred location is undefined, put vic/Melbourne as the default location.
     */
    private GetBestCityName(): string {
        const location = this.props.preferredLocation;

        if (location.isKnown && location.value.displayName) {
            return location.value.displayName;
        }

        return "Melbourne";
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        preferredLocation: state.location.preferredLocation,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    }
}

export default connect(mapStateToProps)(ContactForm);