import { Divider, List, ListItem, ListItemText } from "@mui/material";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationState } from "../../appState";
import { Api } from "../../Services/Api";
import { LegalDocuments, UserConsentRequest } from "../../Services/LegalDocumentsContracts";
import DialogClose from "../Dialog/DialogClose";
import { Dispatch } from "../Dispatch";
import { TaskKind } from "../LoginValidation/LoginValidationEntities";
import { DeclineProfileValidationProcess, IsUnderProfileValidationMode } from "../LoginValidation/LoginValidationHelper";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { BrandedImage, GetBrandedUrl } from "../Utils/BrandedContentUrls";
import { LegalConsentStatement } from "./LegalDocsConsent";
import OpenInNew from "@mui/icons-material/OpenInNew";

interface PropsFromStore {
    LegalDocs: LegalDocuments;
    LayoutMode: UILayoutMode;
}

/** The modal which prompts the user to consent for legal documents whenever any document is updated. */
class LegalDocumentsConsentCore extends Component<PropsFromStore> {
    constructor(props: PropsFromStore) {
        super(props);
    }

    render() {
        return (
            <div className="tnc-consent-popup-panel">
                <DialogClose />
                
                <LegalConsentHeader {...this.props} />

                <div className="legal-docs-consent-content-area">
                    <div className="legal-docs-info">
                        There have been updates to our terms of service. Please review and accept to login.
                    </div>

                    <List>
                        <DocListItem Title={"Collection Statement"} Url={this.props.LegalDocs.CollectionStatementUrl} />
                        <DocListItem Title={"Privacy Policy"} Url={this.props.LegalDocs.PrivacyPolicyUrl} />
                        <DocListItem Title={"Terms and Conditions"} Url={this.props.LegalDocs.TermsAndConditionsUrl} />
                    </List>
                </div>

                <div className="tnc-consent-popup-footer tnc-consent-popup-panel-header-footer-props">
                    <LegalDocsConsentFooter {...this.props} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        LegalDocs: state.legalDocuments.LegalDocumentsPack,
        LayoutMode: state.uiLogicControl.LayoutMode
    };
}

export const LegalDocumentsConsentDialog = connect(mapStateToProps)(LegalDocumentsConsentCore);

/**
 * Header of the modal
 */
const LegalConsentHeader: React.FC<{}> = () => {

    return (
        <div className="tnc-consent-popup-header tnc-consent-popup-panel-header-footer-props">
            <img src={GetBrandedUrl(BrandedImage.Logo)} width="auto" height="30px" alt="logo" className="logo" />
        </div>
    );
}

/**
 * Footer of the modal. Contains consent statement and buttons to accept or decline.
 */
const LegalDocsConsentFooter: React.FC<PropsFromStore> = (props) => {
    const [IsChecked, setIsChecked] = useState(false);

    function UpdateCheckedState(isChecked: boolean) {
        setIsChecked(isChecked);
    }

    const AcceptBtnClass = IsChecked ? "tnc-consent-popup-btn-accept" : "disable-click tnc-consent-popup-btn-decline"

    return (
        <>
            <LegalConsentStatement IsAccepted={IsChecked} ToggleConsent={() => UpdateCheckedState(!IsChecked)} LegalDocsUrls={props.LegalDocs} ShouldIncludeCheckBox={true} />
            <div className="tnc-btns-group">
                <button className="tnc-consent-popup-btn-decline" type="button" onClick={() => DeclineLegalConsent()}>Decline</button>
                <button className={AcceptBtnClass} type="button" onClick={() => AcceptLegalConsent(IsChecked, props)}>OK</button>
            </div>
        </>
    );
}

/** User declined the consent. Close the dialog and logout. */
export function DeclineLegalConsent() {    
    Dispatch.Dialog.CloseTopDialog();

    if (IsUnderProfileValidationMode()) {
        DeclineProfileValidationProcess();
    }    
}

/** User has agreed to the legal statements. Send a request to backend to update user consent and close the dialog. */
async function AcceptLegalConsent(isAcceptChecked: boolean, props: PropsFromStore) {

    // Do not proceed if the checkbox is not checked.
    if (!isAcceptChecked) return;

    const consentRequest: UserConsentRequest = {
        LegalDocsId: props.LegalDocs.LegalDocumentsVersion!
    }

    var consentResult = await Api.LegalDocuments.UpdateUserConsent(consentRequest);

    if (!consentResult.isSuccess) {
        // TODO: Show an error message instead of logout?        
        DeclineLegalConsent();
        return;
    }

    Dispatch.LegalDocuments.ConsentStatus({ IsConsentRequired: false, LegalDocumentsToConsent: null });
    Dispatch.Dialog.CloseTopDialog();
    Dispatch.LoginValidation.CompleteSubTask(TaskKind.TncAcceptance);
}

/** List item contains the doc type and the link to the actual document. */
const DocListItem: React.FC<{ Title: string; Url: string | null }> = (props) => {

    if (!props.Url) return null;

    return (
        <>
            <Divider />
            <Link to={{ pathname: props.Url }} target="_blank" rel="noopener">
                <ListItem button>
                    <ListItemText primary={props.Title} />
                    <OpenInNew fontSize="small" />
                </ListItem>
            </Link>
        </>
    );
}