import { FeatureFlags } from "../../Config/FeatureFlags";
import { Api } from "../../Services/Api";
import { ApiGeoPoint } from "../../Services/BookingEntities";
import { CheckCabServiceRequest } from "../../Services/LocationEntities";
import { PickupServiceCheckState, ServiceCheckStatus } from "../Booking/BookingEntities";
import { ProcessServiceabilityResult } from "../Booking/CheckCabService";
import { ProcessServiceabilityResultV2 } from "../Booking/CheckCabServiceAvailabilityV2";
import { Dispatch } from "../Dispatch";

/** Set address serviceability status in the store based on the result of API calls to check serviceability. */
export async function SetAddressServiceability(place: google.maps.places.PlaceResult) {

    Dispatch.AddressPoints.SetAddressValidity({
        ServiceabilityState: {
            status: ServiceCheckStatus.CheckInProgress
        }
    });

    let availabilityResult;

    if (FeatureFlags.BookingApiV2) {
        availabilityResult = await CheckCabServiceabilityV2(place);
    }
    else {
        availabilityResult = await CheckCabServiceabilityV1(place);
    }    

    Dispatch.AddressPoints.SetAddressValidity({
        ServiceabilityState: availabilityResult
    });
}

/** Check serviceability using V1 API (Booking API). */
async function CheckCabServiceabilityV1(place: google.maps.places.PlaceResult): Promise<PickupServiceCheckState> {

    const request: CheckCabServiceRequest = {
        GooglePlaceDetail: place,
        GoogleOdrdTripId: null, // TODO: implement
    }

    const result = await Api.Location.CheckCabServiceByPlaceDetail(request);
    return ProcessServiceabilityResult(result);
}

/** Check serviceability using V2 API (Global booker API). */
async function CheckCabServiceabilityV2(place: google.maps.places.PlaceResult): Promise<PickupServiceCheckState> {
    
    const geoLocation: ApiGeoPoint = {
        Latitude: place.geometry!.location.lat(),
        Longitude: place.geometry!.location.lng(),
    }

    const result = await Api.MakeBooking.CheckCabServiceAvailability(geoLocation);
    return ProcessServiceabilityResultV2(result);
}