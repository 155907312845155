import { ApiFailureTestingDispatcher } from "./ApiFailureTesting/FailureTestingActions";
import { ConditionDispatchV2 } from "./Condition/Redux/ConditionDispatcherV2";
import { DialogDispatcher } from "./Dialog/DialogDispatcher";
import { GoogleMapDispatcher } from "./GoogleMap/GoogleMapDispatcher";
import { LocationDispatcher } from "./Location/LocationDispatcher";
import { TncDispatcher } from "./TncPrivacy/TncPrivacyDispatcher";
import { TabDispatch } from "../widgets/NavBar/TabDispatch";
import { UserProfileNavDispatcher } from "./User/UserProfileNavDispatcher";
import { UILogicControlDispatch } from "./UILogicControl/UILogicControlDispatch";
import { MyBookingsDispatchV2 } from "./MyBookings/MyBookingsDispatchV2";
import { LoginValidationDispatch } from "./LoginValidation/LoginValidationDispatch";
import { BookingTemplatesDispatch } from "./BookingTemplate/BookingTemplatesDispatch";
import { PaymentDispatchV2 } from "./Payment/PaymentDispatcherV2";
import { Dialog2Dispatch } from "./Dialog2/Redux/Dialog2Dispatch";
import { AuthDispatchV2 } from "./Authentication/AuthDispatchV2";
import { VerificationDispatchV2 } from "./Verification/VerificationDispatchV2";
import { FeaturesDispatch } from "./WhatsNew/FeaturesDispatch";
import { TrackingLinksDispatch } from "./TrackingLinks/Redux/TrackingLinksDispatch";
import { BookingDispatchV2 } from "./Booking/Redux/BookingDispatchV2";
import { LegalDocumentsDispatch } from "./LegalDocuments/LegalDocumentsDispatch";
import { AddressPointsDispatch } from "./AddressPoints/Redux/AddressPointsDispatch";

/** Global accessor to dispatch Redux actions.
 *  Usage: Dispatch.Auth.AuthTokenCreate(payload);
 */
export const Dispatch = {

    /** dispatch an Authorisation action */
    Auth: AuthDispatchV2,

    /** dispatch a Booking or NonBooking action */
    Booking: BookingDispatchV2,

    /** dispatch an ApiFailureTesting action */
    ApiFailureTesting: new ApiFailureTestingDispatcher(),

    /** dispatch a Condition action */
    Condition: ConditionDispatchV2,

    /** dispatch a Dialog action */
    Dialog: new DialogDispatcher(),

    /** dispatch a GoogleMap action */
    GoogleMap: new GoogleMapDispatcher(),

    /** dispatch a Location action */
    Location: new LocationDispatcher(),

    /** dispatch a MyBookings action */
    MyBookings: MyBookingsDispatchV2,
    
    /** dispatch a Tab action */
    Tab: TabDispatch,

    /** dispatch a TNC action */
    Tnc: new TncDispatcher(),

    /** dispatch a Verification action */
    Verification: VerificationDispatchV2,

    /** dispatch a User profile nav action */
    UserProfileNav: new UserProfileNavDispatcher(),

    /** dispatch a payment card action */
    Payment: PaymentDispatchV2,    

    /** dispatch a UI & logic controlling action */
    UILogicControl: UILogicControlDispatch,

    /** dispatch a LoginValidation action */
    LoginValidation: LoginValidationDispatch,

    /** Dispatch Booking Template actions */
    BookingTemplate: BookingTemplatesDispatch,

    /** Dispatch a Dialog (V2) action */
    DialogV2: Dialog2Dispatch,

    /** Dispatch a WhatsNew features action  */
    Features: FeaturesDispatch,

    /** Dispatch a TrackingLinks action */
    TrackingLinks: TrackingLinksDispatch,

    /** Dispatch a Legal Documents action */
    LegalDocuments: LegalDocumentsDispatch,

    /** Dispatch an Address Points action. */
    AddressPoints: AddressPointsDispatch
};