import Checkbox from '@mui/material/Checkbox';
import React from 'react'
import { useAppState } from '../../../Redux/ReduxHooks';
import { Dispatch } from '../../Dispatch';
import { CheckPhone } from '../../PhoneNumber/CheckPhone';
import { LocationIndex } from '../BookingEntities';
import './BookingUseCompanyDetails.scss';
import { PerLocationProps } from './PerLocationProps';

/** 
 *  A checkbox above the contact details UI for a location in the booking form.
 *  When you tick the box, it populatse the contact details from the logged in account.
 *  It's "company details" if you are an Account user, otherwise "my contact details" for normal logged in users. 
 */  
export const BookingUseCompanyDetails: React.FC<PerLocationProps> = (props) => {

	const userProfile = useAppState(state => state.authentication.UserProfile);
	if (!userProfile) return null;

	const hasAccounts = userProfile.Accounts.length > 0;
	const prefixText = hasAccounts ? "Use company details" : "Use my contact details";

	const locationName = props.Location === LocationIndex.Pickup ? "pickup" : "dropoff";
	const label = `${prefixText} for ${locationName}`;

	return (
		<div className="booking-fields-panel company-details-section">
			<Checkbox onChange={OnCheckboxChanged} />
			<span>
				{label}
			</span>
		</div>
	);

	/**
	 * When the user ticks or unticks the checkbox.
	 */
	function OnCheckboxChanged(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {

		if (checked) {
			Dispatch.Booking.ContactName(props.Location, userProfile!.ContactName);

			const rawPhone = userProfile!.ContactPhone;
			const result = CheckPhone.FromRawNumber(rawPhone);

			if (result.IsValid) {
				Dispatch.Booking.ContactPhone(props.Location, result.Value);
			}
		}
		else {
			Dispatch.Booking.ClearContactName(props.Location);
			Dispatch.Booking.ClearContactPhone(props.Location);
		}
	}
}
