import React from 'react';
import { GoogleMap, Marker, DirectionsRenderer } from 'react-google-maps';
import { GeoPoint } from '../../modules/Location/Entities';
import { toGoogleLatLng } from './toGoogleLatLng';
import { getContentUrl, ContentURL } from '../../modules/Utils/ContentURL';
import { DirectionsData } from '../../modules/GoogleMap/GoogleMapEntities';
import { GoogleMapAction } from '../../modules/GoogleMap/GoogleMapActions';
import { GetValues } from '../../Config/MyAppConfig';
import { GetBrandedUrl, BrandedImage } from '../../modules/Utils/BrandedContentUrls';
import { GetMyDevice } from "../../Config/MyAppConfig";
import { DeviceKind } from "../../Config/Entities/DeviceKind";
import TaxiMarker from './TaxiMarker';

/** The dispatch function will be injected by react-redux connect()
 WARNING: the connect call is in GoogleMapWrapped, the dispatch function is used in DirectionsGoogleMap, and it is defined here. I realise it's a mess but i can't untangle it.
 I need to use it in DirectionsGoogleMap, which extends this class, so it has to be defined here. BUT it's not actually available until the connect() call, done by GoogleMapWrapped. It was desired to separate out GoogleMapWrapped as a container component, and leave this 100% presentation. */
interface PropsFromStore {
    dispatch: (action: GoogleMapAction) => void;
}

/** Public / Application-meaningful properties for our Google Map component */
export interface GoogleMapProps {

    /** Metadata about Google's recommended route from A to B. Used to render a snail trail. Optional. */
    directions?: DirectionsData;

    pickupLocation?: GeoPoint;

    dropoffLocation?: GeoPoint;

    /** Geopoint for the center of the map. When combined with [zoom], this sets the bounds. */
    mapCenter: GeoPoint;

    /** Google Maps zoom level. Big = zoomed in. */
    zoom?: number;

    /** Taxi number */
    carNumber: string | null;

    /** Location of taxi for status Accepted & Pickedup */
    vehicleLocation?: GeoPoint;

    /** URL of the pickup location pin. This can be different in different contexts like Bookings and Saved addresses. */
    PickupMarkerUrl: string;
}

/** Note: don't use this class directly in your code; use the Wrapped version */
export class GoogleMapRaw extends React.Component<GoogleMapProps & PropsFromStore> {

    // used by derived classes to access the GoogleMap object at runtime, e.g. to manually .SetBounds.
    mapObject: React.RefObject<GoogleMap>;

    constructor(props: GoogleMapProps & PropsFromStore) {
        super(props);
        this.mapObject = React.createRef<GoogleMap>();
    }

    render() {
        return (
            <GoogleMap
                ref={this.mapObject}
                center={toGoogleLatLng(this.props.mapCenter)}
                zoom={this.props.zoom}
                options={{ disableDefaultUI: true, zoomControl: GetMyDevice() !== DeviceKind.Phone }} // Mobile device doesn't show zoom button
            >
                {this.renderMarker(this.props.pickupLocation, this.props.PickupMarkerUrl)}
                {this.renderMarker(this.props.dropoffLocation, getContentUrl(ContentURL.images.MapMarkerDropoff))}
                {this.renderTaxiMarker()}
                
                {this.renderDirections()}
            </GoogleMap>
        );
    }

    /** Renders a map marker if the corresponding location prop is defined. */
    private renderMarker(location: GeoPoint | undefined, markerImageUrl: string): JSX.Element | null {
        if (!location) return null;

        return <Marker position={toGoogleLatLng(location)} icon={{ url: markerImageUrl }} />;
    }

    /** Renders a taxi if the corresponding location prop is defined. */
    private renderTaxiMarker(): JSX.Element | null {
        if (!this.props.vehicleLocation) return null;

        return <TaxiMarker taxiLocation={this.props.vehicleLocation} carNumber={this.props.carNumber} />;
    }

    /** Renders the path / snail trail, if it exists in the props. */
    renderDirections(): JSX.Element | null {
        if (!this.props.directions) return null;

        const options: google.maps.DirectionsRendererOptions = {
            suppressMarkers: true,
            polylineOptions: { strokeColor: GetValues().BrandColour, strokeWeight: 3 }
        };

        return (
            <DirectionsRenderer
                options={options}
                directions={this.props.directions.directions}
            />
        );
    }
}