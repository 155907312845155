import React from "react";
import { connect } from 'react-redux';
import "./AccountBookings.scss";
import { ApplicationState } from "../../../appState";
import { Dispatch } from "../../Dispatch";
import { Switch } from "@mui/material";

interface PropsFromStore {
    IsBookOnAccountSelected: boolean;
}

/** On/off slider for whether the booking should be on account in the Booking Creation form */
class BookOnAccountToggleCore extends React.Component<PropsFromStore> {

    // Event handler for changes to the Book on Account slider
    UpdateAccountBookingStatus = () => {

        const isNowOnAccount = !this.props.IsBookOnAccountSelected;

        if (isNowOnAccount) {
            // incompatible option, as currently implemented
            Dispatch.Condition.ClearPriceGuarantee();
        }
        else {
            Dispatch.Booking.ClearAccountDetails();
        }

        Dispatch.Booking.SetBookOnAccount(isNowOnAccount);
    }

    render() {
        return (
            <div className={`on-account-toggle-section`}>
                <div className={this.props.IsBookOnAccountSelected ? "toggle-label-common on-account-label-selected" : "toggle-label-common"}>Booking on account</div>
                <Switch 
                    color="secondary"
                    checked={this.props.IsBookOnAccountSelected}
                    onChange={this.UpdateAccountBookingStatus}
                />
            </div>            
        );
    }
}

function MapStoreToProps(state: ApplicationState): PropsFromStore {
    return {
        IsBookOnAccountSelected: state.booking.IsOnAccount
    };
}

export const BookOnAccountToggle = connect(MapStoreToProps)(BookOnAccountToggleCore);