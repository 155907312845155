import React from 'react';
import { GoogleMapsEnhance } from '../../widgets/google-maps/GooglePropsEnhancer';
import { GooglePlaceToLocation } from '../../widgets/google-maps/ParseGooglePlace';
import { KnownLocation, LocationSourceKind } from '../Location/Entities';
import { Redirect } from 'react-router-dom';
import { Dispatch } from '../Dispatch';

class UrlLocationCore extends React.Component {

    constructor(props: any) {
        super(props);
        this.callback = this.callback.bind(this);
    }

    UNSAFE_componentWillMount() {
        /* The url from the CMS will be <baseURL>/location/<placeID>. Remove /location/ from the path */
        const placeID = window.location.pathname.substr(10);
        let request = {
            placeId: placeID,
            fields: ['address_components', 'geometry', 'name', 'place_id']
          };
    
          let service = new google.maps.places.PlacesService(document.createElement('div'));
          // Get the place details for the given place ID.
          service.getDetails(request, this.callback);
    }

    callback(place: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
            const location = GooglePlaceToLocation(place);
    
            if (location == null) {
                return;
            }
    
            const userLocation: KnownLocation = {
                isKnown: true,
                source: LocationSourceKind.URLLocation,
                value: location,
            };
    
            Dispatch.Location.userSelectsLocation(userLocation);
        }
    }

    render() {
        return <Redirect to={'/Booking'} />;
    }
}

/** Enhance with google maps loading behaviour */
export const UrlLocation = GoogleMapsEnhance(UrlLocationCore);