import { DateTime } from "luxon";
import { ApplicationState } from "../../appState";
import appstore from "../../appStore";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { BookingLocationV1, BookingPaymentV1, BookingRequestV1b, BookingTimingV1, ClientDetails, ConditionV1 } from "../../Services/BookingEntities";
import { ApiVehicleOption } from "../Condition/Redux/ConditionEntities";
import { ConsiderAddingSilverServiceCondition } from "../Condition/ShouldAddSilverServiceCondition";
import { PreviewFeatureId } from "../Features/FeatureEntities";
import { ShouldIncludeCardIdInBooking } from "../Payment/PaymentHandler";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { ComputeDriverNotes, GetBestLocationContact, GetOverallBookingContact } from "./CreateBookingCommon";
import { OptionalUI } from "./OptionalParts/OptionalUI";
import { ConvertToApiModel } from "./Parcel/ParcelEntities";

export function BuildBookingRequest(withSmsVerification: boolean): BookingRequestV1b {

    // Get data from the store, all booking information is guaranteed via validation before this step.
    const state = appstore.getState();
    const booking = state.booking;
    const condition = state.condition;
    const SelectedCondition = condition.SelectedCondition;

    // Evaluate booking time
    const timeV2 = booking.BookingTimeV2;

    // CAREFUL: [time] is really for backwards compatibility only.
    let time: string = DateTime.now().toISO();
    let localTime: string | null = null;

    if (timeV2.IsImmediate == false) {
        localTime = timeV2.RequestedDate.toISO();
        time = localTime;
    }

    const pickupLocation: BookingLocationV1 = {
        PlaceId: booking.Pickup.Address!.GoogleMapsPlaceId,
        ContactDetails: GetBestLocationContact(booking.Pickup.Contact),
        DriverInstructions: ComputeDriverNotes(booking)
    };

    let dropoffLocation = null;
    if (booking.Dropoff.Address != null) {
        dropoffLocation = {
            PlaceId: booking.Dropoff.Address.GoogleMapsPlaceId,
            ContactDetails: GetBestLocationContact(booking.Dropoff.Contact),
            DriverInstructions: booking.Dropoff.DriverNotes,
        };
    }

    const paymentInfo: BookingPaymentV1 = {
        DeviceData: booking.DeviceData ?? null,
        PaymentCardId: null,
        Fare: null,
        AccountInfo: null,
        PaymentPreAuthOptIn: ShouldBePreAuthorised(state),
        IsFixedFare: condition.IsPriceGuaranteeSelected
    };

    /** Send fixed fare details with every booking request if available. */
    if (condition.FixedFare && SelectedCondition.FixedFare) {
        paymentInfo.Fare = {
            FixedFareId: SelectedCondition.FixedFare.Id,
            FixedFarePartitionKey: condition.FixedFare.FixedFarePartitionKey
        }
    }

    if (ShouldIncludeCardIdInBooking(booking)) {
        paymentInfo.PaymentCardId = booking.PaymentOption?.Card?.CardId ?? null;
    }

    const SelectedAccountData = appstore.getState().booking.AccountData;

    if (SelectedAccountData) {
        paymentInfo.AccountInfo = {
            AccountNumber: SelectedAccountData.SelectedAccount.AccountNumber,
            OrderNumber: SelectedAccountData.OrderNumber ?? null
        };
    }

    const timingInfo: BookingTimingV1 = {
        Time: time,
        LocalTime: localTime,
        IsImmediate: timeV2.IsImmediate
    };

    const clientInfo: ClientDetails = {
        IsMobile: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    }

    const result: BookingRequestV1b = {
        PickupLocation: pickupLocation,
        DropoffLocation: dropoffLocation,
        ContactInfo: GetOverallBookingContact(),
        Payment: paymentInfo,
        Pax: 0,
        Timing: timingInfo,
        Conditions: null,
        SmsChallengeId: null,
        Client: clientInfo,
        ParcelOptions: null,
        GoogleOdrdTripId: null,
    };

    // Google ODRD Trip ID
    if (ShouldIncludeOdrdTripId()) {
        result.GoogleOdrdTripId = booking.GoogleOdrdTripId;
    }

    // Parcel Delivery
    if (booking.DeliveryOption) {
        result.ParcelOptions = ConvertToApiModel(booking.DeliveryOption);
    }

    // SMS Verification
    if (withSmsVerification) {

        const smsChallengeId = appstore.getState().verification.SmsChallengeId!;
        result.SmsChallengeId = smsChallengeId;
    }

    /**
    * Condition check & setting
    */
    if (!!SelectedCondition.ApiVehicle) {
        const vehicleOptions: ApiVehicleOption[] = [SelectedCondition.ApiVehicle];

        ConsiderAddingSilverServiceCondition(vehicleOptions);

        const conditions = vehicleOptions.map(MapVehicleOptionToCondition);
        result.Conditions = conditions;
    }

    return result;
}

/** 
 *  Whether to include Google ODRD Trip ID in CreateBooking requests.
 *  Signed in users can consume a dynamic feature flag; guest users fall back to a static one.
 */
function ShouldIncludeOdrdTripId(): boolean {

    const state = appstore.getState();

    if (OptionalUI.LoggedInUser(state)) {
        return state.features.EnabledPreviews[PreviewFeatureId.GoogleOdrdTripIdInCreates] ?? false;
    }
    else {
        return FeatureFlags.GuestsSendOdrdTripId;
    }
}

/** Decides whether to make PaymentPreAuthOptIn flag true or false. */
function ShouldBePreAuthorised(state: ApplicationState): boolean {

    if (!FeatureFlags.PreAuth) return false;

    // Only applicable for CNP bookings.
    if (!state.booking.PaymentOption?.Card) return false;

    // Fixed fare details is required for pre-auth bookings.
    if (!state.condition.SelectedCondition.FixedFare) return false;

    return true;
}

/**
 * Convert the internal representation, ApiVehicleOption, to the V1-specific API data contract, ConditionV1.
 */
function MapVehicleOptionToCondition(vehicleOption: ApiVehicleOption): ConditionV1 {
    return {        
        ID: vehicleOption.ApiId
    };
}