import { ApplicationState } from "../../../appState";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { LoginStatusKind } from "../../Authentication/AuthEntities";
import { BookingWidgetModeKind } from "../../BookingTemplate/BookingTemplateEntities";
import { PreviewFeatureId } from "../../Features/FeatureEntities";
import { BookingFormKind, UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { CalculateIsBookingOnAccount } from "../NewBookingForm";

/** 
 *  Reusable logic for whether to render various pieces of the Booking Form.
 *  These can vary with feature settings, login state, account users, parcel mode, etc.
 */
export namespace OptionalUI {

    /**
     * Whether to display the end of delivery dropdown list.
     */
    export function DeliveryOption(state: ApplicationState): boolean {

        const isBookingOnAccount = CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile);

        // intentionally not supported for templates; the user should decide for each booking
        const isTemplateMode = state.uiLogicControl.BookingForm.BookingWidgetMode !== BookingWidgetModeKind.Booking;

        const isParcelMode = state.uiLogicControl.BookingForm.ActiveBookingForm === BookingFormKind.ParcelBooking;

        const isFeatureEnabled = !!state.features.EnabledPreviews[PreviewFeatureId.EndOfDelivery];

        return isBookingOnAccount && !isTemplateMode && isParcelMode && isFeatureEnabled;
    }

    /**
     * Whether the user can book on account.
     * Makes the account on/off toggle visible, also account-only preview features.
     */
    export function CanBookOnAccount(state: ApplicationState): boolean {

        // global feature flag
        if (!FeatureFlags.BookOnAccount) return false;

        // device-specific feature flag
        const isMobileDevice = MobileLayout(state);
        if (isMobileDevice && !FeatureFlags.MobileBookingOnAccount) return false;

        // not logged in
        const userProfile = state.authentication.UserProfile;
        if (!userProfile) return false;

        // no accounts
        if (userProfile.Accounts.length <= 0) return false;

        // DVA accounts have extra custom behaviour that isn't yet supported on mobile
        // TODO: what behaviour? Is this still the case?
        if (isMobileDevice && userProfile.Accounts.some(account => account.IsDVA)) return false;

        // all OK!
        return true;
    }

    /**
     * True when the application is in mobile layout mode.
     * This normally means we've identified that the user is on a mobile device.
     * However, a desktop user can also swap to the layout mode.
     */
    export function MobileLayout(state: ApplicationState): boolean {
        return state.uiLogicControl.LayoutMode === UILayoutMode.Mobile;
    }

    /**
     * True when the user is logged in. Lots of extra UI and functionality becomes available.
     */
    export function LoggedInUser(state: ApplicationState): boolean {
        return state.authentication.LoginStatus === LoginStatusKind.LoggedIn;
    }
}