import { Location, GeoPoint } from "../../modules/Location/Entities";
import { IsSupportedStateCode } from "../../modules/Location/Metadata";
import { AddressV2Details } from "../../Services/MakeBookingContracts";

/** Simpler representation of a google places result
 * Returns null if we couldn't convert the result to a proper location. */
export function GooglePlaceToLocation(place: google.maps.places.PlaceResult, preferRegionName: boolean = false): Location | null
{
    // this can happen if the user searched for text with no results
    if (!place.address_components) {
        return null;
    }

    // another case we can't support
    if (!place.geometry) return null;
    if (!place.geometry.location) return null;

    // these are the parts we hope to read.
    let stateCode: string | undefined;
    let stateName: string | undefined;
    let cityName: string | undefined;
    let suburbName: string | undefined;
    let countryCode: string | undefined;

    for (let component of place.address_components) {

        // state
        if (component.types.includes('administrative_area_level_1')) {
            stateCode = component.short_name.toLowerCase();
            stateName = component.long_name;
        }

        // city
        if (component.types.includes('administrative_area_level_2')) {
            cityName = component.long_name;
        }

        // suburb
        if (component.types.includes('locality')) {
            suburbName = component.long_name;
        }

        // Country
        if (component.types.includes('country')) {
            countryCode = component.short_name;
        }
    }

    const googleGeoPoint = place.geometry.location;

    const geoPoint: GeoPoint = {
        latitude: googleGeoPoint.lat(),
        longitude: googleGeoPoint.lng(),
    };

    const isValid: boolean = (stateCode !== undefined) && IsSupportedStateCode(stateCode);

    let displayName: string | null = null;

    if (place.name) {
        displayName = place.name;
    }

    if (preferRegionName) {
        if (cityName) displayName = cityName;
        if (suburbName) displayName = suburbName;
    }

    const result: Location =
    {
        displayName: displayName,
        geoPoint: geoPoint,
        isStateServiced: isValid,
        isValid: isValid,
        stateCode: stateCode || "",
    };

    return result;
}

/** Simpler representation of a google places result which is used for BookingControllerV2
 * Returns null if we couldn't convert the result to a proper location. 
 */
export function GooglePlaceToAddressV2Details(place: google.maps.places.PlaceResult): AddressV2Details
{
    // these are the parts we hope to read.
    let premise: string | null = null; 
    let number: string | null = null;
    let unit: string | null = null;
    let streetName: string | null = null;
    let suburbName: string = "";
    let stateCode: string | null = null;
    let country: string = "";
    let postCode: string | null = null;

    // this can happen if the user searched for text with no results
    if (!!place.address_components) {

        for (let component of place.address_components) {

            // premise
            if (component.types.includes('premise')) {
                premise = component.short_name;
            }
    
            // number
            if (component.types.includes('street_number')) {
                number = component.short_name;
            }
    
            // unit 
            if (component.types.includes('subpremise')) {
                unit = component.short_name;
            }
    
            // streetName
            if (component.types.includes('route')) {
                streetName = component.long_name;
            }
    
            // suburb
            if (component.types.includes('locality')) {
                suburbName = component.long_name;
            }
            else if (component.types.includes('postal_town')) {
                suburbName = component.long_name;
            }
    
            // state
            if (component.types.includes('administrative_area_level_1')) {
                stateCode = component.short_name;
            }
    
            // country
            if (component.types.includes('country')) {
                country = component.long_name;
            }
    
            // postCode
            if (component.types.includes('postal_code')) {
                postCode = component.long_name;
            } 
        }
    }

    const addressDetails: AddressV2Details =
    {
        Place: premise,
        Number: number,
        Unit: unit,
        StreetName: streetName,
        Suburb: suburbName,
        State: stateCode,
        Country: country,
        PostCode: postCode
    };

    return addressDetails;
}