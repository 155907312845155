import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import './SignupLogin.scss';
import { Dispatch } from '../../Dispatch';
import { Credentials } from '../AuthEntities';
import { DialogKind } from '../../Dialog/DialogEntities';
import { CredentialPopupTrigger } from "../../UILogicControl/UILogicControlEntities";
import { getContentUrl, ContentURL }  from '../../Utils/ContentURL';
import { DataLoadingStatus } from "../../Condition/Redux/ConditionEntities";
import CredentialsController from "./CredentialsController";
import AuthImplV2 from "./AuthImplV2";
import { VerificationTrigger } from '../../Verification/VerificationEntities';
import { ContactNumberValidationResult, ContactNumberKind, ErrorMessagePlacementKind, AuthType } from './LoginEntities';
import { DropLeadingZero } from '../../../utils/Formattingutil';
import { Api } from "../../../Services/Api"; 
import { IsValidEmailFormat, CreateCredentialForSignup } from "./CredentialHelper";
import { FeatureFlags } from '../../../Config/FeatureFlags';
import SilentLogin from './SilentLogin';
import { GetValues } from '../../../Config/MyAppConfig';
import { RouteUrls } from '../../../RouteUrls';
import { ValidateContactNumber } from '../../Utils/PhoneValidation';
import ResponsiveNameInput from "../Login/ResponsiveNameInput";
import ResponsiveEmailInput from "../Login/ResponsiveEmailInput";
import ResponsivePasswordInput from "../Login/ResponsivePasswordInput";
import { ResponsiveInputWrapper } from "../../Authentication/Mobile/ResponsiveInputWrapper";
import Checkbox from '@mui/material/Checkbox';
import { LogEvent } from '../../../utils/LogEvent';
import ResponsiveNumberInput from "../Login/ResponsiveNumberInput";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { StartSmsOutcome, StartSmsVerification } from '../../Verification/StartSmsVerification';
import { LegalDocuments } from '../../../Services/LegalDocumentsContracts';
import { LegalConsentStatement } from '../../LegalDocuments/LegalDocsConsent';

interface PropsFromStore {
    CredentialPopupTriggerSource: CredentialPopupTrigger;
    Credentials: Credentials;
    IsApiRunning: boolean;
    ErrorMessage: string | undefined;
    ErrorMessagePlacement: ErrorMessagePlacementKind;
    IsSilentLoginActive: boolean;
    UnderMobileMode: boolean;
    LegalDocsV2: LegalDocuments;
}

interface SignupState {
    TncAccepted: boolean;
}

/**
 * Renders the signup popup container for the new Auth0 interface.
 */
class Signup extends Component<PropsFromStore, SignupState> {

    constructor(props: PropsFromStore) {
        super(props);

        this.state = { TncAccepted: false }

        this.tncOnToggle = this.tncOnToggle.bind(this);
    }

    tncOnToggle() {
        this.setState({ TncAccepted: !this.state.TncAccepted});
    }

    render() {
        return(
            <div className="signup-login-popup-panel signup-panel-height">
                <ResponsiveInputWrapper>
                    <ResponsiveNameInput />
                </ResponsiveInputWrapper>
                <ResponsiveInputWrapper>
                    <ResponsiveNumberInput />
                </ResponsiveInputWrapper>
                <ResponsiveInputWrapper>
                    <ResponsiveEmailInput IsNonEmailLoginAllowed={false} />
                </ResponsiveInputWrapper>
                <ResponsiveInputWrapper>
                    <ResponsivePasswordInput EnforcePasswordComplexity={true} EnterPressedEvent={() => SignupButtonPressed(this.props.Credentials, this.state.TncAccepted, !this.props.IsApiRunning)} />
                </ResponsiveInputWrapper>
                {FeatureFlags.LegalDocumentsV2 ?
                    <LegalConsentStatement IsAccepted={this.state.TncAccepted} ToggleConsent={this.tncOnToggle} LegalDocsUrls={this.props.LegalDocsV2} ShouldIncludeCheckBox={true} />
                    :
                    <SignupTncConsent TncAccepted={this.state.TncAccepted} ToggleConsent={this.tncOnToggle} />}
                <SignUpButton TncAccepted={this.state.TncAccepted} {...this.props} />
                <LinkToLogin {...this.props} />

                {this.props.ErrorMessage && this.props.ErrorMessagePlacement === ErrorMessagePlacementKind.Popup && <p className="credentials-auth0-error-message" dangerouslySetInnerHTML={{__html: this.props.ErrorMessage}}></p>}
                {this.props.IsSilentLoginActive && <SilentLogin Credentials={this.props.Credentials} AuthType={AuthType.Signup} /> }
            </div>      
        );
    }
}

export function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        CredentialPopupTriggerSource: state.uiLogicControl.Credentials.PopupTriggerSource,
        Credentials: state.authentication.Credentials,
        IsApiRunning: state.uiLogicControl.Credentials.ApiRunningStatus !== DataLoadingStatus.Idle,
        ErrorMessage: state.authentication.ErrorMessage,
        ErrorMessagePlacement: state.authentication.ErrorMessagePlacement,
        IsSilentLoginActive: state.authentication.IsSilentLoginActive,
        UnderMobileMode: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        LegalDocsV2: state.legalDocuments.LegalDocumentsPack
    };
}

export default connect(mapStateToProps)(Signup);

interface SignupTncConsentProps extends SignupState {
    ToggleConsent: () => void;
}

/**
 * Link to login
 */
const LinkToLogin: React.FC<PropsFromStore> = (props) => {

    if (props.UnderMobileMode) return null;

    return (
        <div className="signup-popup-login-group" onClick={() => ClickToLogin()}>
            <p>Have a profile already? <span>Log in here</span></p>
        </div>
    );

    function ClickToLogin() {

        if (props.IsApiRunning) return;

        new CredentialsController().ClosePopup();

        Dispatch.Dialog.ShowDialog(DialogKind.LogIn);
    }
}

/**
 * Toggle & tnc consent
 */
export const SignupTncConsent: React.FC<SignupTncConsentProps> = (props) => {
    return (
        <div className="signup-popup-tnc-group">
            <Checkbox checked={props.TncAccepted} onChange={props.ToggleConsent} inputProps={{ 'aria-label': 'primary checkbox' }} />
            <p className="">I accept the <span><a href={RouteUrls.TermsAndConditions} target="_blank">Terms and Conditions</a></span> and <span><a href={GetValues().PrivacyUrl} target="_blank">Privacy Policy</a></span></p>
        </div>
    );
} 

/**
 * Button for sign up
 */
export const SignUpButton: React.FC<PropsFromStore & SignupState> = (props) => {

    const btnActivity = IsSignupReady(props.Credentials, props.TncAccepted) ? "active" : "inactive";

    const btnSize = props.CredentialPopupTriggerSource === CredentialPopupTrigger.FromMenu ? "large" : "small";

    const proceedBtnStyle = "credential-btn-loading credential-btn-proceed-" + btnSize + "-" + btnActivity;

    const proceedBtnContent = props.IsApiRunning && !props.UnderMobileMode ? <img className="credential-loading" src={getContentUrl(ContentURL.images.Loading)} alt="Loading"/> : "Create my profile";

    return (
        <div className="signup-popup-btn-group">
            {props.CredentialPopupTriggerSource === CredentialPopupTrigger.FromGuestBooking && <button className="credential-btn-skip" onClick={() => new CredentialsController().ClosePopup() }>Skip for now</button>}
            <button className={proceedBtnStyle} onClick={() => SignupButtonPressed(props.Credentials, props.TncAccepted, !props.IsApiRunning)}>{proceedBtnContent}</button>
        </div>
    );
}

/**
 * How are ready?
 * 1) Profile name has been filled and validated;
 * 2) Contact number has been filled and validated;
 * 3) Email or username has been filled and validated;
 * 4) Password has been filled and validated;
 * 4) Tnc has been accepted.
 */
function IsSignupReady(credentials: Credentials, tncConsent: boolean): boolean {

    if (!credentials.FirstName) return false; // only validate first name here, and ignore last name here

    if (!ValidateContactNumber(!!credentials.ContactNumber ? credentials.ContactNumber : "", credentials.CountryInfo!.CountryCode).IsValid) return false;

    if (!credentials.Email || !IsValidEmailFormat(credentials.Email)) return false;

    if (!credentials.Password) return false;

    if (!tncConsent) return false;

    return true;
}

/**
 * This function is triggered when the customer clicks the "Sign up" button
 */
function SignupButtonPressed(credentials: Credentials, tncConsent: boolean, idle: boolean) {

    if (!idle) return;

    if (!IsSignupReady(credentials, tncConsent)) return;

    Dispatch.Verification.WasTriggeredBy(VerificationTrigger.Signup);
    Dispatch.UILogicControl.StartCredentialsApiRequest();
    ExecSignup(credentials);
}

/**
 * This function is triggered after the customer clicked the "Sign up" button, if all inputs are valid.
 * Decide next step:
 *    1> Check user existence;
 *    2> Mobile or landline;
 *       If mobile -> send verification code and show verification popup;
 *       If landline -> signup directly;
 */
async function ExecSignup(credentials: Credentials) {
    const existResult = await Api.User.CheckUserExistenceByUserName(credentials.Email!);

    /**
     * Step 1:
     * To check the result of user existence.
     * We do not handle else here, which means this API call failed or something.
     * For this kind of situation, which failed to check whether this username is registered, Auth0 will do another check.
     * So, safe enough.
    */
    if (existResult.isSuccess && !existResult.value) {

        const result: ContactNumberValidationResult = ValidateContactNumber(credentials.ContactNumber!, credentials.CountryInfo!.CountryCode);

        /**
         * Step 2:
         * "ContactNumberValidationResult.IsValid === true" is guaranteed here, no need to check anymore
         */
        if (result.ContactNumberKind === ContactNumberKind.Mobile) {
            StartToVerifyMobile(credentials);
        }
        else if (result.ContactNumberKind === ContactNumberKind.Landline) {
            CreateProfile(credentials);
        }
    }
    else if (existResult.isSuccess && existResult.value) {
        Dispatch.Auth.LoginErrorMessage("This email is already registered");   
        Dispatch.Auth.PlaceErrorMessage(ErrorMessagePlacementKind.Email);
        Dispatch.UILogicControl.EndCredentialsApiRequest();
        Dispatch.Verification.WasTriggeredBy(VerificationTrigger.Booking);
    }
}

/**
 * This function will trigger a call to Auth0 for signup using landline number
 */
function CreateProfile(credentials: Credentials) {

    if (FeatureFlags.Auth0RedirectInChildWindow) {
            Dispatch.Auth.ShowSilentLogin();
    }
    else {
        new AuthImplV2().SignUpAuth0(CreateCredentialForSignup(credentials));
    }
}

/**
 * Verification for mobile
 */
async function StartToVerifyMobile(credentials: Credentials) {

    const phoneNumber = credentials.CountryInfo.CountryCode + DropLeadingZero(credentials.ContactNumber!);
    const result = await StartSmsVerification(phoneNumber);

    // this is highly non-obvious, but the corresponding "Start" was in SignupButtonPressed().
    Dispatch.UILogicControl.EndCredentialsApiRequest();

    if (result.Outcome === StartSmsOutcome.Success) {

        LogEvent.SendVerificationCodeToMobileSuccess();

        new CredentialsController().ClosePopup();
        Dispatch.Dialog.ShowDialog(DialogKind.Verification);      
    }
    else {
        LogEvent.SendVerificationCodeToMobileFail(result.ErrorMessage);

        Dispatch.Auth.LoginErrorMessage("Failed to send verification code to your mobile, please try again.");
        Dispatch.Verification.WasTriggeredBy(VerificationTrigger.Booking);
    }
}