import { GeoPoint } from "../../Location/Entities";

/**
 *  The input to GetGooglePredictions().
 *  It is a customised version of Google's QueryAutocompletionRequest.
 *  Some Google features we won't support are not included, resulting in a simpler model.
 */
export interface PlaceSearchInput {

    /** The text typed by the user which will search for a Google Place */
    RawInputText: string;

    /** 
     *  The callback that will handle results in the success case only.
     *  The failure case (wrong API key) will be handled internally.
     */
    SuccessCallback: (results: google.maps.places.AutocompletePrediction[]) => void;

    /** City (locality) vs street address */
    LocationType: LocationTypes;

    /** Optional nearby location to focus search on. */
    PreferNearbyTo?: GeoPoint;
}

/** Which type of place is being searched for. This will map to the (types) field in Google. We support a small subset of the possible values, hence this custom enum. */
export enum LocationTypes {

    /** Cities, Suburbs, etc. Suitable for the context location chooser. */
    CityOrArea = 1,

    /** Street address or place of business. Suitable for the booking widget addresses. */
    Address = 2,
}