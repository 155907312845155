import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { LocationIndex } from "./BookingEntities";

/** Consider to clear driver notes populated with selection of favourite addresses. This is called when an address is cleared from an address input field. */
export function ConsiderClearingDriverNotesFromFavourite(isPickup: boolean) {
    const bookingState = appstore.getState().booking;

    if (isPickup) {

        // cleared pickup address is not a favourite address - no need to proceed
        if (!bookingState.Pickup.Favourite) return;

        // pickup driver notes is the exact note from the selected favourite address (not edited manually by the user)
        if (bookingState.Pickup.Favourite.PickupNotes === bookingState.Pickup.DriverNotes) {
            Dispatch.Booking.ClearDriverNotes(LocationIndex.Pickup);
        }

        // dropoff address from a favourite address is cleared first, but dropoff driver notes is not cleared because it was edited by the user.
        // then pickup address from a favourite address is cleared. there is no 'Dropoff driver notes' field in this case
        if (!bookingState.Dropoff.Favourite) Dispatch.Booking.ClearDriverNotes(LocationIndex.Dropoff);
    }
    else {

        // cleared dropoff address is not a favourite address - no need to proceed
        if (!bookingState.Dropoff.Favourite) return;

        // dropoff driver notes is the exact note from the selected favourite address (not edited manually by the user)
        if (bookingState.Dropoff.Favourite.DropoffNotes === bookingState.Dropoff.DriverNotes) {
            Dispatch.Booking.ClearDriverNotes(LocationIndex.Dropoff);
        }

        // pickup address from a favourite address is cleared first, 
        // then dropoff address from a favourite address is cleared, but dropoff driver notes is not cleared in the above check because it was edited by the user.
        // since both addresses are not from favourite addresses, there is no 'Dropoff driver notes' field in this case, and should be cleared even if it is edited by the user.
        if (!bookingState.Pickup.Favourite) Dispatch.Booking.ClearDriverNotes(LocationIndex.Dropoff);
    }
}