import { Api } from "../../Services/Api";
import { ErrorResult } from "../Utils/ErrorEntities";
import appstore from "../../appStore";
import { HasLogInProperly } from '../Authentication/AuthHelper';
import { ComputeDriverNotes, GetOverallBookingContact, GetBestLocationContact } from "../Booking/CreateBookingCommon";
import { SetErrorMessages } from "../Utils/CommonHelpers";
import { LogBookingCreation } from "../Booking/LogBookingCreation";
import { Dispatch } from "../Dispatch";
import { GetMyBrand } from "../../Config/MyAppConfig";
import { Brand } from "../../Config/Entities/Brand";
import { LoadBookingDetails } from "./BookingLoadersV2";
import { BookingAuthority } from "../../Services/BookingEntities";
import { BookingAccount, BookingLocation, BookingPaymentMethod, ContactDetails, CreateBookingRequest, CreateBookingResponse } from "../../Services/MakeBookingContracts";

/** Use CreateBooking() in V2 booking service to create a booking using Global Booker. */
export async function CreateBookingV2(): Promise<CreateBookingResponse | ErrorResult> {

    var result = null;

    const createBookingRequest = ConstructCreateBookingRequest();

    const serviceResult = await Api.MakeBooking.CreateBooking(createBookingRequest);
    Dispatch.UILogicControl.BookingFormApiEnd();

    if (serviceResult.isSuccess) {
        result = serviceResult.value;

        // Booking creation logging
        LogBookingCreation(false);

        // Get booking details

        const bookingKey: BookingAuthority = {
            bookingId: parseInt(result.BookingIdentifier),
            hashCode: result.WriteAccessCode
        };

        await LoadBookingDetails(bookingKey);
    } else {
        result = SetErrorMessages(serviceResult);
    }

    return result;
}

/** Generate booking request V2 */
function ConstructCreateBookingRequest(): CreateBookingRequest {

    const state = appstore.getState();
    const bookingPayload = state.booking;
    const selectVehicle = state.condition.SelectedCondition.ApiVehicle;
    const isUserLoggedIn: boolean = HasLogInProperly(true);
    const bookingContact = GetOverallBookingContact();

    const contactDetails: ContactDetails = {
        Identifier: EvaluateUserIdentifier(isUserLoggedIn),
        Name: bookingContact.Name,
        PhoneNumber: bookingContact.PhoneNumber,
    };

    const pickupContact = GetBestLocationContact(bookingPayload.Pickup.Contact);

    /** Careful: the Identifier field is required for account verification by the Cabcharge API in Book360. */
    const fullPickupContact: ContactDetails = {
        Identifier: EvaluateUserIdentifier(isUserLoggedIn),
        Name: pickupContact.Name,
        PhoneNumber: pickupContact.PhoneNumber,
    };

    const pickupLocation: BookingLocation = {
        Contact: fullPickupContact,
        Address: bookingPayload.Pickup.Address!,
        DriverInstructions: ComputeDriverNotes(bookingPayload)
    }

    let dropoffLocation: BookingLocation | null = null;

    if (bookingPayload.Dropoff.Address) {

        const dropoffContact = GetBestLocationContact(bookingPayload.Dropoff.Contact);

        dropoffLocation = {
            Contact: {
                Identifier: null,
                Name: dropoffContact.Name,
                PhoneNumber: dropoffContact.PhoneNumber,
            },
            Address: bookingPayload.Dropoff.Address,
            DriverInstructions: null
        }
    }

    const rawTime = bookingPayload.BookingTimeV2;

    const createBookingRequest: CreateBookingRequest = {
        ContactDetails: contactDetails,
        PickupLocation: pickupLocation,
        DropoffLocation: dropoffLocation,
        BookingTime: {
            IsImmediate: rawTime.IsImmediate,
            RequestedDate: rawTime.IsImmediate ? null : rawTime.RequestedDate.toISO(),
        },
        AccountDetails: EvaluateAccountDetails(isUserLoggedIn),
        VehicleType: selectVehicle!.ApiId, // selectVehicle is defined at this point.
        PaymentMethod: EvaluatePaymentMethod(isUserLoggedIn)
    }

    return createBookingRequest;
}

/** 
 * Calculate the payment method to BookingControllerV2 when make a booking
 * We will pass "Cabcharge Account Payment" option when below requirements are all met, otherwise paying driver directly
 *   1) Logged in as Cabcharge users;
 *   2) Selected account booking.
 */
function EvaluatePaymentMethod(isUserLoggedIn: boolean): BookingPaymentMethod {

    // check log in status
    if (!isUserLoggedIn) return BookingPaymentMethod.Cash;

    // "Cabcharge Account Payment" is only for logged in as Cabcharge users
    let brand = GetMyBrand();
    
    /**
     * Cabcharge account payment option is exclusive for Cabcharge users.
     * Book 360 is currently configured to log in as Cabcharge users.
     * 
     * Todo: 
     * Remove "&& brand !== Brand.Book360" if this requirement is not needed. 
     */
    if (brand !== Brand.CabCharge && brand !== Brand.Book360) return BookingPaymentMethod.Cash;

    // check account details
    if (!EvaluateAccountDetails(isUserLoggedIn)) return BookingPaymentMethod.Cash;

    return BookingPaymentMethod.AccountPassThrough;
}

/** Get the user identifier */
function EvaluateUserIdentifier(isUserLoggedIn: boolean): string | null {

    if (!isUserLoggedIn) return null;

    return appstore.getState().authentication.UserProfile!.TokenUserId;
}

/** Get the account information */
function EvaluateAccountDetails(isUserLoggedIn: boolean): BookingAccount | null {

    if (!isUserLoggedIn) return null;

    const selectedAccountData = appstore.getState().booking.AccountData;

    if (!selectedAccountData) return null;

    return {
        OrderNumber: !selectedAccountData.OrderNumber ? null : selectedAccountData.OrderNumber,
        AccountNumber: selectedAccountData.SelectedAccount.AccountNumber 
    };
}