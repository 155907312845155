import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../appState';
import { withProps } from "recompose";
import { withGoogleMap, withScriptjs, WithScriptjsProps, WithGoogleMapProps } from 'react-google-maps';
import { GoogleMapProps } from './GoogleMapRaw';
import { DirectionsGoogleMap } from './DirectionsGoogleMap';
import { BookingTrackingProps } from '../../modules/MyBookings/UI/ExpandedSection';
import { CalculateTaxiLocation, CalculateTrackingMapCenter, CalculatePickupLocation, CalculateDropoffLocation } from './CalculateMapTrackingProps';
import { OpenMapWithBookingDetails } from './OpenMapWithBookingDetails';
import './GoogleMapWrapped.scss';
import { GetGoogleMapsApiUrl } from './GetGoogleMapsApiUrl';
import { BrandedImage, GetBrandedUrl } from '../../modules/Utils/BrandedContentUrls';

// This attaches [mapElement] and [containerElement] props
const MapWrap = withGoogleMap(DirectionsGoogleMap);

// this attaches [googleMapURL] and [loadingElement] props
const MapAndScriptWrap = withScriptjs(MapWrap);

// this type has all the new props we add
type PropsToAdd = WithGoogleMapProps & WithScriptjsProps;

// default values for our new props
const propsToAdd: PropsToAdd = {
    mapElement: <div className="google-maps-map" style={{ height: `100%` }} />,
    containerElement: <div className="google-maps-container" />,
    googleMapURL: GetGoogleMapsApiUrl(),
    loadingElement: (<div style={{ height: `100%` }} />),
};

// attach the default values
const propsEnhancer = withProps<PropsToAdd, GoogleMapProps>(propsToAdd);
export const GeneralPurposeBookingMap = propsEnhancer(MapAndScriptWrap);

/** Connection from store state to local props. */
function mapStateToProps(state: ApplicationState): GoogleMapProps {
    return {
        directions: state.googleMap.directions,
        pickupLocation: state.googleMap.pickupLocation,
        dropoffLocation: state.googleMap.dropoffLocation,
        mapCenter: state.googleMap.mapCenter || state.location.reliableLocation.value.geoPoint,
        zoom: state.googleMap.zoomLevel || 14,

        // no booking or car in this context (booking creation)
        carNumber: null,
        PickupMarkerUrl: GetBrandedUrl(BrandedImage.MapMarkerPickup)
    };
}

export const GoogleMapWrapped = connect(mapStateToProps)(GeneralPurposeBookingMap);

// GoogleMapWrappedForTaxiTracking is similar to GoogleMapWrapped, but for tracking purpose; 
export const GoogleMapWrappedForTaxiTracking: React.FC<BookingTrackingProps> = (props) => {
    return (
        <div className="tracking-map-wrapper" onClick={() => {OpenMapWithBookingDetails(props.BookingDetails.BookingID)}}>
            <GeneralPurposeBookingMap
                mapCenter={CalculateTrackingMapCenter(props.BookingDetails.TrackingInfo)}
                zoom={15}
                vehicleLocation={CalculateTaxiLocation(props.BookingDetails.TrackingInfo)}
                pickupLocation={CalculatePickupLocation(props.BookingDetails)}
                dropoffLocation={CalculateDropoffLocation(props.BookingDetails)}
                carNumber={props.BookingDetails.TrackingInfo.CarNumber}
                PickupMarkerUrl={GetBrandedUrl(BrandedImage.MapMarkerPickup)}
            />
        </div>
    );
}