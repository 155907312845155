import { FormControl, InputLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { LocationIndex } from "../../Booking/BookingEntities";
import { Dispatch } from "../../Dispatch";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";
import { AddressPoint } from "../Redux/AddressPointsEntities";

interface PropsFromStore {
    ActiveAddressPoint: AddressPoint;
}

interface DriverNotesProps {
    Location: LocationIndex;
}

// Maximum number of characters allowed in one driver notes field.
const DriverNotesMaxLength = 320;

/** Component containing 'Notes to driver' input fields. Reused for both pickup and dropoff driver notes input. */
const DriverNotesCore: React.FC<PropsFromStore & DriverNotesProps> = (props) => {

    const notesInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    const [remainingChars, setRemainingChars] = useState(DriverNotesMaxLength);

    useEffect(() => {
        if (props.Location === LocationIndex.Pickup) {
            notesInputRef.current!.value = props.ActiveAddressPoint.PickupNotes ?? "";
        }
        else {
            notesInputRef.current!.value = props.ActiveAddressPoint.DropoffNotes ?? "";
        }

        CalculateRemainingLength();
        
    }, [props.ActiveAddressPoint]);

    /* Update store with user input value */
    function UpdateNotes(e: React.FocusEvent<HTMLInputElement>) {
        if (props.Location === LocationIndex.Pickup) Dispatch.AddressPoints.RefreshPickupNotes(e.target.value);
        else Dispatch.AddressPoints.RefreshDropoffNotes(e.target.value);
        
    }

    /* Calculate how many more characters can be entered. This number is used to display a message in the UI. */
    function CalculateRemainingLength() {
        const currentLength = notesInputRef.current!.value.length;
        const remaining = DriverNotesMaxLength - currentLength;

        setRemainingChars(remaining);
    }

    const label = props.Location === LocationIndex.Pickup ? "Pickup notes for driver (optional)" : "Destination notes for driver (optional)";
    const notesInfoClass = remainingChars > 30 ? "input-label custom-label-notes" : "input-label custom-label-notes warning-text";
    const notesInfoIconClass = remainingChars > 30 ? "custom-img-layout-notes" : "custom-img-layout-notes display-icon";

    return (
        <div className="address-point-input driver-notes-input">
            <FormControl variant="standard">
                <InputLabel shrink htmlFor="notes-input">
                    {label}
                </InputLabel>
                <TextField
                    maxRows={3}
                    multiline={true}
                    id="notes-input"
                    inputRef={notesInputRef}
                    fullWidth={true}
                    variant="outlined"
                    onBlur={UpdateNotes}
                    className="simple-textfield"
                    inputProps={{ maxLength: DriverNotesMaxLength }}
                    onInput={CalculateRemainingLength}
                    placeholder="e.g. unit, gate and floor number"
                />
                <div className="character-calculation-div">
                    {remainingChars <= 30 && <img
                        alt="warning"
                        src={getContentUrl(ContentURL.images.Diagnostic.warning)}
                        className={notesInfoIconClass}
                    />}
                    <label className={notesInfoClass}>{remainingChars} characters left</label>
                </div>
            </FormControl>
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        ActiveAddressPoint: state.addressPoints.ActiveAddressPoint
    }
}

export const AddressDriverNotes = connect(mapStateToProps)(DriverNotesCore);